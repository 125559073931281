<template>
  <v-container fluid>
    <div class="shadow rounded-5">
      <div class="md-card-content">
        <div>
         <div class="d-flex justify-space-between align-center">
           <SvgIcon class="text-base font-semibold text-black" text="Event Schedules">
             <template v-slot:icon>
               <ScheduleIcon/>
             </template>
           </SvgIcon>

           <div class="d-flex justify-end align-center gap-x-4">
             <v-menu
                 v-model="menu1"
                 :close-on-content-click="false"
                 :nudge-right="40"
                 min-width="290px"
                 offset-y
                 transition="scale-transition"
             >
               <template v-slot:activator="{ on }">
                 <v-text-field
                     v-model="date1Formatted"
                     class="w-150 q-text-field shadow-0"
                     hide-details
                     outlined
                     readonly
                     v-on="on"
                     dense
                 ></v-text-field>
               </template>
               <v-date-picker
                   v-model="searchParams.from_date"
                   @input="menu1 = false"
               ></v-date-picker>
             </v-menu>

             <v-menu
                 v-model="menu2"
                 :close-on-content-click="false"
                 :nudge-right="40"
                 min-width="290px"
                 offset-y
                 transition="scale-transition"
             >
               <template v-slot:activator="{ on }">
                 <v-text-field
                     v-model="date2Formatted"
                     class="w-150 q-text-field shadow-0"
                     hide-details
                     outlined
                     readonly
                     v-on="on"
                     dense
                 ></v-text-field>
               </template>
               <v-date-picker
                   v-model="searchParams.to_date"
                   @input="menu2 = false"
               ></v-date-picker>
             </v-menu>
             <v-btn
                 class="bg-blue text-white"
                 height="40"
                 @click="getSchedules"
             >
               Go
             </v-btn>
           </div>
         </div>
          <br><br>

          <div class="table-responsive">

            <table class="table border-collapse">
              <thead>
              <tr class="tr-neon tr-rounded opacity-70">
                <th class="text-left">
                  Check In
                </th>
                <th>
                  <!--                  <div class="text-left">Cashier</div>-->
                  <div class="search_column">
                    <v-autocomplete
                        v-model="searchParams.cashier_name"
                        :items="cashier_names"
                        :loading="isLoading"
                        :search-input.sync="cashier_name"
                        class="q-autocomplete"
                        filled
                        flat
                        hide-details
                        item-text="name"
                        item-value="name"
                        label="Cashier"
                        solo
                        @change="getFilterData"
                    ></v-autocomplete>
                  </div>
                </th>
                <th>
                  <!--                  <div class="text-left">Customer</div>-->
                  <div class="search_column">
                    <v-autocomplete
                        v-model="searchParams.name"
                        :items="names"
                        :loading="isLoading"
                        :search-input.sync="name"
                        class="q-autocomplete"
                        filled
                        flat
                        hide-details
                        item-text="name"
                        item-value="name"
                        label="Customer"
                        solo
                        @change="getFilterData"
                    ></v-autocomplete>
                  </div>
                </th>

                <th class="text-left">
                  Ticket Name
                </th>
                <th class="text-left">
                  Ticket Code
                </th>
              </tr>
              </thead>

              <tbody>
              <tr
                  v-for="eventCustomer in participantsList"
                  :key="eventCustomer.id"
              >
                <td>
                  {{ eventCustomer.check_in | timeStamp }}
                </td>

                <td>
                  {{ eventCustomer.scanner_user_name }}
                </td>
                <td>
                  <span
                      class="pointer"
                      @click="showUserModel(eventCustomer.customer_id)"
                  >
                    {{ eventCustomer.first_name }} {{ eventCustomer.last_name }}
                  </span>
                </td>

                <td>
                  {{ eventCustomer.ticket_name }}
                </td>
                <td>
                  {{ eventCustomer.ticket_code }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <v-row>
            <v-col cols="4"></v-col>
            <v-col cols="4">
              <v-pagination v-model="page" :length="totalPages" class="new-pagination"></v-pagination>
            </v-col>
            <v-col class="d-flex align-center justify-end" cols="4">
              <div class="d-flex align-center justify-end text-sm">
                <span>Result</span>
                <div style="width: 80px">
                  <v-select
                      v-model="perPage"
                      :items="[50, 100, 500]"
                      :menu-props="{ bottom: true, offsetY: true }"
                      class="q-autocomplete text-sm"
                      flat
                      hide-details
                      solo
                      @change="getSchedules"
                  ></v-select>
                </div>
                <span>Per Page</span>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>

    <customer-model v-bind="userModel" @close="userModel.userID = null"/>
  </v-container>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import CustomerModel from "./../../Clients/Customer/CustomerModel";
import ScheduleIcon from "@/assets/images/nav_icon/Schedule.svg"
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  props: {
    event: Object,
  },
  components: {
    SvgIcon,
    CustomerModel,
    ScheduleIcon
  },
  data() {
    return {
      btnShow: false,
      participantsList: [],
      names: [],
      name: null,
      isLoading: false,
      page: 1,
      perPage: 50,
      totalPages: 1,
      cashier_names: [],
      cashier_name: null,
      menu1: null,
      menu2: null,
      userModel: {userID: null, type: "details"},
      searchParams: {
        events: [],
        from_date: moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      },
    };
  },

  created() {
    setTimeout(() => {
      this.btnShow = true;
    }, 10);
  },
  mounted() {
    if (this.$store.getters.getEvents.status == false) {
      this.$store.dispatch("loadEvents");
    }
    this.getSchedules();
  },

  computed: {
    ...mapGetters({
      checkReadPermission: "checkReadPermission",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
          "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
          "Do MMM YYYY"
      );
    },
    events() {
      return this.$store.getters.getEvents.data;
    },
  },
  watch: {
    page() {
      this.getCustomerList();
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    cashier_name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("cashier_name", val);
    },
  },
  methods: {
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    toggle(type, field) {
      this.$nextTick(() => {
        if (
            this.searchParams[field] != null &&
            this.searchParams[field].length == this[type].length
        ) {
          this.searchParams[field] = [];
        } else {
          this.searchParams[field] = this[type];
        }
        this.getSchedules();
      });
      this.$forceUpdate();
    },
    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";

      if (
          this.searchParams[field] != null &&
          this.searchParams[field].length == this[type].length
      ) {
        icon = "mdi-close-box";
      }
      if (
          this.searchParams[field] != null &&
          this.searchParams[field].length > 0 &&
          this.searchParams[field].length != this[type].length
      )
        icon = "mdi-minus-box";

      return icon;
    },

    getSchedules() {
      let url =
          "&from_date=" +
          this.searchParams.from_date +
          "&to_date=" +
          this.searchParams.to_date;
      if (this.searchParams.name == "All") {
        this.searchParams.name = null;
      } else if (
          typeof this.searchParams.name != "undefined" &&
          this.searchParams.name != null
      ) {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.cashier_name == "All") {
        this.searchParams.cashier_name = null;
      } else if (
          typeof this.searchParams.cashier_name != "undefined" &&
          this.searchParams.cashier_name != null
      ) {
        url += "&cashier_name=" + this.searchParams.cashier_name;
      }
      if (this.event.id != undefined) {
        url += "&event_id=" + this.event.id;
      }
      this.showLoader("Loading");
      this.$http
          .get(`venues/events/event-schedules?page=${this.page}${url}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.participantsList = response.data.data;
            }
            this.hideLoader();
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    filterSearch(type, val) {
      if (val == "All") return;
      this.isLoading = true;
      this.$http
          .get(
              "venues/memberships/members/filters?field=" + type + "&search=" + val
          )
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.isLoading = false;
              if (type == "name") {
                this.names = response.data.data;
                this.names.unshift(val);
                this.names.unshift("All");
              }
              if (type == "cashier_name") {
                this.cashier_names = response.data.data;
                this.cashier_names.unshift(val);
                this.cashier_names.unshift("All");
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getFilterData() {
      this.page = 1;
      this.getSchedules();
    },

    customerForward(id) {
      this.$router.push(
          {
            name: "CustomersByID",
            params: {id: id},
          },
          () => {
          }
      );
    },
  },
};
</script>
