<template>
<v-container fluid>
    <v-row>
        <v-col cols="12">
            <v-card class="">
                 <v-card-actions>
                    <div class="heading"> <h3 class="text-center">Seat Sections</h3></div>
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text class="">
                <div class="map-section-list">
                    <div class="map-section-list-item" v-for="(section,index) in mapData.sections" :key="index" >
                        <v-row>
                            <v-col cols="4" lg="4" md="4" sm="4">
                                <v-card  
                                    elevation="0"
                                    :color="section.section_bg_color"
                                    style="width: 100%; height: 20px; border:1px solid black;"
                                    >
                                </v-card>
                            </v-col>
                            <v-col cols="8" lg="8" md="8" sm="8">
                                 <h4 style="overflow-x: clip;">{{ section.section_name }}</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="12" md="12" sm="12">
                                <div class="section-info">
                                    <p>
                                        <span> Total Seats: {{  section.section_name != 'Stage' && section.seats?section.seats.length:0 }}</span>
                                        <span>Tickets: {{  getAssignedTicket(section.seats) }}</span>
                                        <span>Booked: {{  section.booked_seats?section.booked_seats.length: 0 }}</span>
                                    </p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row v-if="mapData">
         <v-col lg="12" md="12" sm="12" v-if="section">
            <v-card class="seat-map-editor">
                <v-card-actions>
                    <v-row>
                        <v-col md="8">
                            <div class="heading">
                                <h3>Your Tickets</h3>
                            </div>
                        </v-col>
                        <v-col md="2" style="text-align:right;">
                            <h3>Total (inc.Tax): {{ totalAmount | toCurrency }}</h3>
                        </v-col>
                        <v-col md="2" style="text-align:right;">
                            <v-btn
                                @click="openEventMapBookingForm"
                                class="white--text teal-color"
                                :disabled="selectedProducts.length == 0"
                                ><v-icon>mdi-plus</v-icon> PARTICIPANT</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-divider></v-divider>
                <div class="ticket-list">
                     <v-row class="row">
                        <v-col lg="12" md="12" sm="12">
                            <div class="text-center" v-if="selectedProducts.length">
                                <div class="ticket-item"  v-for="(ticket, index) in selectedProducts" :key="index">
                                    <v-chip
                                        class="ma-2"
                                        color="#062b48"
                                        text-color="white"
                                    >
                                    <v-icon left>
                                        mdi-ticket
                                    </v-icon>
                                    {{ ticket.seat_name }}: {{ ticket.name }} x {{ ticket.quantity }} = {{ ticket.quantity * ticket.price  }}
                                    </v-chip>
                                    <v-chip
                                        class="ma-2"
                                        color="#062b48"
                                        text-color="white"
                                         v-for="(seat, tindex) in ticket.seats" :key="`s_${tindex}`"
                                    >
                                    <v-icon left width="16" class="seat-icon">
                                        mdi-seat
                                    </v-icon>
                                    {{ seat.seat_name }}
                                    </v-chip>
                                    
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
          
        </v-col>
        <v-col lg="8" md="8" sm="12">
            <v-card class="seat-map-editor">
                <v-card-actions>
                    <div class="heading"><h3>Event Map</h3></div>
                </v-card-actions>
                <v-divider></v-divider>
                <div class="parent-map-canvas">
                    <SeatMapBookingCanvas 
                        :renderCanvas="renderCanvas" 
                        :renderSection="renderSection" 
                        :mapData="mapData" 
                        :mapSections="mapData.sections" 
                        :seatMapStyles="seatMapStyles"
                        @selectedSection="selectedSection"
                    />
                </div>
            </v-card>
        </v-col>
        <v-col lg="4" md="4" sm="12" v-if="section">
            <MapSectionSeats 
                :mapSection="section"
                :tickets="tickets"  
                :renderSeats="renderSeats"
                :eb="true"  
                @close="closeEdit" 
                @save="saveSection" 
                @closeUpdate="closeUpdateMapSectionSeat" 
                @selectSeat="selectProduct"
            />
        </v-col>
       
    </v-row>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
    <EventBookingMapForm
      v-bind="eventForm"
      :selectedProducts="selectedProducts"
      @close="eventForm.showeventForm = false"
      @booked="openOrderCloseBooking"
      @cancel="getEventDetails"
      @pay="openOrderCloseBooking"
    ></EventBookingMapForm>
 </v-container>

</template>
<script>
import SeatMapBookingCanvas from '@/components/SeatMap/SeatMapBookingCanvas.vue';
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import MapSectionSeats from "@/components/SeatMap/MapSectionSeats.vue";
import EventBookingMapForm from "../EventDetails/EventBookingMapForm.vue";
import moment from "moment";
export default {
    components: { SeatMapBookingCanvas,MapSectionSeats, ConfirmModel,EventBookingMapForm },
    props: {
        event: { type: Object, default: null },
        mapReload: {type: Boolean, default: false}
    },
    watch: {
        mapReload: {
            immediate: true,
            handler(val) {
                if (val && this.event && this.event.seat_map_id && this.isMounted) {
                    console.log("mapreload",val);
                    this.setMapData();
                }
            },
        }
    },
    data() {
        return {
            editImageHover: false,
            confirmModel: {
                id: null,
                title: null,
                description: null,
            },
            mapData: {},
            section: null,
            mapSections: [],
            newSectionName: '',
            renderCanvas: false,
            renderSection: false,
            refreshMap: 0,
            rotateSection: null,
            selectedSeats: [],
            renderSeats: false,
            tickets: [],
            isMounted: false,
            chip1: true,
            chip2: true,
            chip3: true,
            chip4: true,
            selectedProducts: [],
            bookingForm: {},
            eventForm: {},
        };
    },
    computed: {
        seatMapStyles() {
            return {
                backgroundColor: this.mapData.map_bg_color,
                width: this.mapData.map_width+"px",
                height: this.mapData.map_height+"px",
                margin: "0 auto",
                border: "1px solid "+this.mapData.map_bg_color,
                borderRadius:"1px",
            };
        },
        totalAmount() {
            if (this.selectedProducts.length) {
                const totalPrice = this.selectedProducts.reduce((total, product) => total + (product.total_price * product.quantity), 0);
                return totalPrice.toFixed(2);
            }
            return 0;
        }
    },
    mounted() {
        if (this.event && this.event.seat_map_id) {
            this.tickets = this.event.tickets;
            this.isMounted = true;
            this.setMapData();
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        confirmActions(data) {
            if (data.type == "delete_section") {
                this.deleteSection(data.id);
            }
            this.confirmModel.id = null;
        },
        setMapData() {
            if (this.event && this.event.seat_map_id) {
                this.showLoader("Loading");
                this.$http.get(`venues/events/event-map?event_id=${this.event.id}`)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data && response.data.data) {
                            this.mapData.isPlanSet = true;
                            this.mapData = response.data.data.seat_map;
                            this.renderCanvas = false;
                            this.$nextTick(() => {
                                this.renderCanvas = true;
                            })
                        }else{
                            this.showError('Data not found');
                        }
                        this.hideLoader();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.hideLoader();
                });  
            }
        },
        closeEdit() {
            this.renderCanvas = false;
        },
        saveMapSectionDesign() { },
        selectedSection(mapSection) {
            this.renderSeats = false;
            this.renderSection = false;
            this.renderCanvas= false;
            this.section = null;
            let ms = Object.assign({}, mapSection);
            this.mapData.sections.forEach((s) => {
                if (s.seat_map_section_id === ms.seat_map_section_id) {
                    s.selected = true;
                } else {
                    s.selected = false;
                }
            });
            this.filterBookedSeats(ms);
            this.$nextTick(() => {
                this.renderCanvas= true;
                this.renderSeats = true;
            })
            
        },
        filterBookedSeats(mapSection) {
            if (mapSection.booked_seats && mapSection.booked_seats.length) {
                const bookedSeats = mapSection.booked_seats;
                mapSection.seats.forEach((seat) => {
                    let seatFind = bookedSeats.find((bs) => bs.seat_name === seat.seat_name && bs.seat_map_section_id === seat.seat_map_section_id);
                    if (seatFind !== undefined) {
                        seat.is_booked = true;
                        seat.status = seatFind.order_status_id == 4?'paid':'unpaid';
                    }
                })
            }
            this.section = Object.assign({}, mapSection);
        },
        closeUpdateMapSectionSeat() { },
        saveSection() { },
        getAssignedTicket(seats) {
            // console.log(this.tickets);
            if (seats && seats.length) {
                const eventTicketIds = seats.map(seat => seat.event_ticket_id);
                // Create a Set to store unique and non-undefined event_ticket_ids
                const uniqueEventTicketIdsSet = new Set(eventTicketIds.filter(id => id !== undefined));
                // Convert the Set back to an array
                const uniqueEventTicketIds = Array.from(uniqueEventTicketIdsSet);
                const ticketNames = uniqueEventTicketIds.map(id => {
                    const matchingTicket = this.tickets.find(ticket => ticket.event_ticket_id === id);
                    return matchingTicket ? matchingTicket.name : null;
                });
                if (ticketNames) {
                    return ticketNames.join(', ');
                }
            } else {
                return "Not Assigned"
            }
        },
        selectProduct(seat, action = true) {
            this.showLoader("wait");
            if (seat.is_booked) {
                this.showError("Seat is already booked");
                console.log("seat is already booked");
                return false;
            } else if (seat.status_id == 2) {
                this.showError("Seat is disabled");
                console.log("seat is disabled");
                return false;
            }
            if (!seat.event_ticket_id) {
                this.showError("Ticket not assigned to this seat, please choose an other");
                return false;
            }
            if (!this.seatAlreadySelected(seat) && action) {
                const ticket = this.tickets.find(t => t.event_ticket_id == seat.event_ticket_id);
                if (ticket !== undefined) {
                    if (ticket.ticket_type == "I") {
                        /** check ticket already exist */
                        const productIndex = this.selectedProducts.findIndex((product) => product.event_ticket_id === ticket.event_ticket_id);
                        if (productIndex !== -1) {
                            this.selectedProducts[productIndex].quantity = this.selectedProducts[productIndex].quantity + 1;
                            if (this.selectedProducts[productIndex].seats) {
                                this.selectedProducts[productIndex].seats.push(
                                    {
                                        seat_name: seat.seat_name,
                                        seat_map_section_id: seat.seat_map_section_id,
                                        event_ticket_id: ticket.event_ticket_id,
                                        product_id: ticket.product_id,
                                    }
                                );
                            }
                        } else {
                            let p = {
                                event_id: ticket.event_id,
                                event_ticket_id: ticket.event_ticket_id,
                                name: ticket.name,
                                participant_count: ticket.participant_count,
                                price: ticket.price,
                                quantity: 1,
                                tax_amount: ticket.tax_amount,
                                total_price: ticket.total_price,
                                product_id: ticket.product_id,
                                product_type_id: ticket.product_type_id,
                                seats:[ {
                                        seat_name: seat.seat_name,
                                        seat_map_section_id: seat.seat_map_section_id,
                                        event_ticket_id: ticket.event_ticket_id,
                                        product_id: ticket.product_id,
                                }]
                            };
                            this.selectedProducts.push(p);
                        }
                       
                    } else {
                        this.showError("Only individual ticket supported");
                        return false;
                    }
                }
            } else {
                if (this.selectedProducts && this.selectedProducts.length) {
                    const productIndex = this.selectedProducts.findIndex((p) => p.event_ticket_id === seat.event_ticket_id);
                    if (productIndex !== -1) {
                        if (this.selectedProducts[productIndex] && this.selectedProducts[productIndex].seats && this.selectedProducts[productIndex].seats.length) { 
                            const seatIndex = this.selectedProducts[productIndex].seats.findIndex(s => s.seat_name === seat.seat_name && s.seat_map_section_id == seat.seat_map_section_id);
                            if (seatIndex !== -1) {
                                
                                this.selectedProducts[productIndex].quantity = this.selectedProducts[productIndex].quantity - 1;
                                this.selectedProducts[productIndex].seats.splice(seatIndex, 1);
                            }
                            if (this.selectedProducts[productIndex].seats.length === 0) {
                                this.selectedProducts.splice(productIndex, 1);
                            }
                        }
                       
                    }
                }
            }
            this.hideLoader();
        },
        seatAlreadySelected(seat) {
            if (this.selectedProducts && this.selectedProducts.length > 0) {
                const productIndex = this.selectedProducts.findIndex((product) => product.event_ticket_id === seat.event_ticket_id);
                if (productIndex !== -1) {
                    if (this.selectedProducts[productIndex] && this.selectedProducts[productIndex].seats && this.selectedProducts[productIndex].seats.length) {
                        const selectedSeat = this.selectedProducts[productIndex].seats.find(item => {
                            return item.seat_name === seat.seat_name && item.seat_map_section_id == seat.seat_map_section_id; 
                        });
                        if (selectedSeat !== undefined) {
                            return true;
                        }
                    }
                } 
            }
            return false; 
        },
        productAlreadySelected(seat) {
            
            if (this.selectedProducts && this.selectedProducts.length > 0) {
                const selectedProduct = this.selectedProducts.find(item => {
                    return item.seat_name === seat.seat_name && item.seat_map_section_id == seat.seat_map_section_id; 
                });
                return selectedProduct;
            } else {
                return false;
            }
        },
        openEventMapBookingForm() {
             this.eventForm = {
                showeventForm: true,
                start_time: moment(this.event.start_time, "hh:mm a").format("HH:mm:ss"),
                end_time: moment(this.event.end_time, "hh:mm a").format("HH:mm:ss"),
                start_date: this.event.start_date,
                end_date: this.event.end_date,
                event_name: this.event.name,
                facility_id: this.event.facility_id,
                event_id: this.event.id,
                date: moment(this.event.start_date, "YYYY-MM-DD").isAfter()
                ? moment(this.event.start_date, "YYYY-MM-DD").format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD"),
                venue_service_id: this.event.venue_service_id,
                service: this.event.venue_service
                ? this.event.venue_service.service.name
                : "NA",
                id: this.event.id != null ? this.event.id : 0,
                event_type_id: this.event.event_type_id,
                image_path: this.event.image_path,
                location: this.event.location,
                participants: this.event.participants ? this.event.participants : 0,
                sales: this.event.sales ? this.event.sales : 0,
            };
        },
        openOrderCloseBooking(orderId) {
            this.eventForm.showeventForm = false;
            this.selectedProducts = [];
            this.section = null;
            this.$emit("booked", orderId);
        },
        getEventDetails() {
            this.$emit("getEventDetails");
        }
        
        
        

    }
};
</script>
<style scoped>
.map-section-list {
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #ccc;
    width: 100%;
    overflow-y: hidden;
}
.map-section-list-item {
    display: inline-block; /* Display items in a single row */
    padding: 10px; /* Add spacing between items */
    border: 1px solid #ddd; /* Optional: Add a border around each item */
    background-color: #f5f5f5; /* Optional: Add a background color for visualization */
    width: 180px;
}
.seat-map-editor {
  position: relative;
}

.parent-map-canvas{
    width: 100%;
    overflow: auto;
    margin-top:5px;
    min-height: 480px;
}

.selected-seat{
    width: 35px;
    display: inline-block;
    background: #ccc;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px;
}
.seat.selected {
    background: #062b48;
    color: #fff !important;
}
.seat-row {
    width: 100%;
    display:inline-flex;
}

.seat{
    display: inline-block;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    min-width: 40px;
}

.seat:hover {
    background: #ccc;
}
.seat.booked {
    background: #ababab;
    color: #fff;
    cursor: not-allowed;
}

.section-info{
    overflow-x: clip;
}
.section-info > p > span {
    display: block;
    font-size: 12px;
    line-height: 1.6;
}

.section-info h4 {
    padding: 0px 0px 5px 0px;
    overflow-x: clip;
}
.ticket-item {
    display: inline-block;
    border: 1px solid #ccc;
    padding: 2px;
    margin: 10px 5px 0px 5px;
    min-width: 180px;
}
</style>