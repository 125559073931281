<template>
<v-card class="seating-card">
    <v-card-actions>
        <div class="heading"><h3>Assign tickets to map section</h3></div>
    </v-card-actions>
    <v-divider></v-divider>
    <v-card-text class="">
        <v-list v-if="mapData && mapData.sections && mapData.sections.length">
            <v-list-item v-for="(section,index) in mapData.sections" :key="index">
                <v-list-item-content class="cart-product-description">
                    <v-list-item-title class="cart-product-title">
                        <v-row>
                            <v-col cols="2">
                                <v-card  
                                    class="rounded-lg"
                                    elevation="0"
                                    :color="section.section_bg_color"
                                    style="width: 100%; height: 20px; border:1px solid black;">
                                </v-card>
                            </v-col>
                            <v-col cols="7">
                                <div class="section-info">
                                    <h4>{{ section.section_name }}</h4>
                                    <p v-if="section.section_name != 'Stage'">
                                        <span>Total Seats: {{  section.seats?section.seats.length:0 }}</span>
                                        <span>Tickets: {{  getAssignedTicket(section.seats) }}</span>
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="3" v-if="section.section_name != 'Stage' && section.seats && section.seats.length">
                                <v-icon class='dark-blue-color ma-1 tc_icon' @click="assignSeatModel(section,'ad')" title="Seat Activate/Deactivate" v-if="checkWritePermission($modules.events.management.slug)">mdi-lock</v-icon>
                                <v-icon class='dark-blue-color ma-1 tc_icon' @click="assignSeatModel(section)" title="Assign Ticket" v-if="checkWritePermission($modules.events.management.slug)">mdi-cog-outline</v-icon>
                            </v-col>
                        </v-row>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card-text>
</v-card>
</template>

<script>
export default {
    props: {
        mapData: { type: Object, default: null },
        eventId: { type: Number, default: null },
        tickets: { type: Array, default: () => [], },
    },
    data() {
        return {
            valid: true,
        };
    },
    methods: {
        assignSeatModel(section,action = null) {
            this.$emit('assignSeatModel',section,action);
        },
        getAssignedTicket(seats) {
            // console.log(this.tickets);
            if (seats && seats.length) {
                const eventTicketIds = seats.map(seat => seat.event_ticket_id);
                // Create a Set to store unique and non-undefined event_ticket_ids
                const uniqueEventTicketIdsSet = new Set(eventTicketIds.filter(id => id !== undefined));
                // Convert the Set back to an array
                const uniqueEventTicketIds = Array.from(uniqueEventTicketIdsSet);
                const ticketNames = uniqueEventTicketIds.map(id => {
                    const matchingTicket = this.tickets.find(ticket => ticket.event_ticket_id === id);
                    return matchingTicket ? matchingTicket.name : null;
                });
                if (ticketNames) {
                    return ticketNames.join(', ');
                }
            } else {
                return "Not Assigned"
            }
        }
    }
};
</script>
<style scoped>
.section-info > p > span {
    display: block;
    font-size: 12px;
    line-height: 1.6;
}

.section-info h4 {
    padding: 0px 0px 5px 0px;
}

@media screen and (min-width: 1540px) and (max-width:1630px) {
  .tc_icon{
    font-size: 20px;
  }
}

@media screen and (min-width: 1400px) and (max-width:1539px) {
  .tc_icon{
    font-size: 16px;
  }
}
</style>
