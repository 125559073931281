<template>
  <v-container fluid no-gutters>
<!--    <v-row v-if="global">-->
<!--      <v-col sm="1"></v-col>-->
<!--      <v-col sm="10" lg="10" style="text-align: center; min-height: 60px">-->
<!--        <SaleTopTab-->
<!--          :salesClass="false"-->
<!--          :logClass="logClass"-->
<!--          :invoiceClass="false"-->
<!--          :creditClass="creditClass"-->
<!--          :refundClass="refundClass"-->
<!--          :cancellationClass="cancellationClass"-->
<!--        />-->
<!--      </v-col>-->
<!--    </v-row>-->
    <FinanceTopBar v-if="global" />

    <div class="app">
      <div class="user-wrapper">
        <div>
          <div class="md-card md-theme-default">
            <div class="md-card-content">
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-space-between align-center ">
                    <SvgIcon class="text-2xl font-semibold" text="Order Details" >
                    </SvgIcon>
                    <v-spacer/>
                    <v-select
                        v-model="perPage"
                        :items="[10, 15, 25, 50]"
                        :menu-props="{ bottom: true, offsetY: true }"
                        class="q-autocomplete shadow-0 m-r-3 mt-2"
                        hide-details
                        outlined
                        @change="searchData"
                        style="max-width: 120px"
                        placeholder="Per Page"
                        dense
                    ></v-select>
                    <v-autocomplete
                        v-model="columns"
                        :items="columnData"
                        class="q-autocomplete  shadow-0 m-r-3 mt-2"
                        hide-details
                        item-text="name"
                        item-value="value"
                        multiple
                        dense
                        outlined
                        placeholder="Table Filter"
                        style="max-width: 200px"
                    >
                      <template
                          v-if="columns.length === columnData.length"
                          v-slot:selection="{ index }"
                      >
                        <span v-if="index === 0">All Fields</span>
                      </template>
                      <template v-else v-slot:selection="{ item, index }">
                        <span v-if="index === 0">{{ item.name }}</span>
                        <span v-if="index === 1">, ...</span>
                      </template>
                      <template v-slot:prepend-inner >
                        <SvgIcon >
                          <template v-slot:icon>
                            <FilterIcon/>
                          </template>
                        </SvgIcon>
                      </template>
                    </v-autocomplete>
                    <v-autocomplete
                        v-model="searchParams.time_intervel"
                        :items="timeDuration"
                        class="q-autocomplete   shadow-0 m-r-3 mt-2"
                        hide-details
                        dense
                        item-text="title"
                        item-value="name"
                        outlined
                        placeholder="Time Period"
                        style="max-width: 200px"
                        @change="changeFxn"
                        height="20"

                    >
                      <template v-slot:prepend-inner>
                        <SvgIcon >
                          <template v-slot:icon>
                            <EventIcon/>
                          </template>
                        </SvgIcon>
                      </template>
                    </v-autocomplete>
                    <div v-if="flag" class="d-flex align-center gap-x-4 mt-2">
                      <v-menu
                          v-model="menu1"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          filled
                          min-width="290px"
                          offset-y
                          transition="scale-transition"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                              v-model="date1Formatted"
                              class="shadow-0 q-text-field"
                              dense
                              flat
                              hide-details
                              outlined
                              readonly

                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="searchParams.start_date"
                            @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                      <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          min-width="290px"
                          offset-y
                          transition="scale-transition"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                              v-model="date2Formatted"
                              class="shadow-0 q-text-field"
                              dense
                              flat
                              hide-details
                              outlined
                              readonly
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="searchParams.end_date"
                            @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                      <v-btn class="mr-2 bg-blue text-white" height="40"  elevation="0" @click="searchData">
                        Go
                      </v-btn>
                    </div>
                    <v-btn
                        v-if="exportPermission || checkPermission"

                        class="export-button mt-2"
                        elevation="0"
                        height="40"
                        @click="salesReportExcelDownload"
                    >
                      <SvgIcon text="Export" >
                        <template v-slot:icon>
                          <ExportIcon/>
                        </template>
                      </SvgIcon>


                    </v-btn>



                    <v-btn
                        v-if="selectedOrders.length"
                        dark
                        class="ml-1 mt-2 white--text blue-color shadow-0"
                        height="40"
                        @click="genrateInvoice"
                    >
                      <SvgIcon text="Generate Invoice" >
                      </SvgIcon>
                    </v-btn>



                  </div>
                </v-col>
              </v-row>
            </div>



            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    class="
                      md-content md-table-content md-scrollbar md-theme-default
                    "
                  >
                    <table class="table border-collapse ">
                      <thead class="">
                        <tr class="opacity-70 tr-rounded tr-neon">
                          <th class="md-table-cell" v-if="logClass">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Total
                              </div>
                              <div class="search_column">
                                <v-btn small>{{
                                  invoiceAmount | toCurrency
                                }}</v-btn>
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('timestamp')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div
                                class="md-table-head-label cursor-pointer"
                                @click="sortColumn('id')"
                              >
                                Timestamp
                                <v-icon class="ml-2" color="#000000" small>
                                  mdi-sort{{
                                    orderBy == "id"
                                      ? "-" +
                                        (orderDir == "ASC"
                                          ? "ascending"
                                          : "descending")
                                      : ""
                                  }}
                                </v-icon>
                              </div>
                              <div class="search_column mt-1">
                                <date-menu
                                  v-model="searchParams.timestamp"
                                  @change="getFilterData"
                                  :range="true"
                                >
                                </date-menu>
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('invoice_no')"
                          >
                            <div
                              style="width: 200px"
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Order No</div>
                              <div class="search_column">
                                <v-text-field
                                  solo
                                  filled
                                  label="Order No"
                                  clearable
                                  v-model="searchParams.invoice_no"
                                  @change="getFilterData"
                                ></v-text-field>
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('date')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div
                                class="md-table-head-label cursor-pointer"
                                @click="sortColumn('order_date')"
                              >
                                Due Date
                                <v-icon class="ml-2" color="#000000" small>
                                  mdi-sort{{
                                    orderBy == "order_date"
                                      ? "-" +
                                        (orderDir == "ASC"
                                          ? "ascending"
                                          : "descending")
                                      : ""
                                  }}
                                </v-icon>
                              </div>
                              <div class="search_column mt-1">
                                <date-menu
                                  v-model="searchParams.date"
                                  @change="getFilterData"
                                >
                                </date-menu>
                              </div>
                            </div>
                          </th>

                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('reason')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">
                                Refund Reason
                              </div>
                              <div class="search_column"></div>
                            </div>
                          </th>

                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('customer_type')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">
                                Customer Type
                              </div>
                              <div class="search_column">
                                <v-autocomplete
                                  label="Customer Type"
                                  :items="[
                                    { name: 'All', id: null },
                                    { name: 'Normal', id: 'normal' },
                                    { name: 'Corporate', id: 'corporate' },
                                    { name: 'Member', id: 'member' },
                                  ]"
                                  v-model="searchParams.customer_type"
                                  item-text="name"
                                  item-value="id"
                                  @change="searchData"
                                  solo
                                  filled
                                >
                                </v-autocomplete>
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('name')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">
                                Customer Name
                              </div>
                              <div class="search_column">
                                <v-autocomplete
                                  solo
                                  filled
                                  label="Name"
                                  v-model="searchParams.name"
                                  item-value="name"
                                  item-text="name"
                                  :items="names"
                                  :loading="isLoading"
                                  :search-input.sync="name"
                                  @change="getFilterData"
                                ></v-autocomplete>
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('mobile')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Mobile</div>
                              <div class="search_column">
                                <v-autocomplete
                                  solo
                                  filled
                                  label="Mobile"
                                  v-model="searchParams.mobile"
                                  item-value="mobile"
                                  item-text="mobile"
                                  :items="phones"
                                  :loading="isLoading"
                                  :search-input.sync="phone"
                                  @change="getFilterData"
                                ></v-autocomplete>
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('email')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Email</div>
                              <div class="search_column">
                                <v-autocomplete
                                  solo
                                  filled
                                  label="Email"
                                  v-model="searchParams.email"
                                  item-value="email"
                                  item-text="email"
                                  :items="emails"
                                  :loading="isLoading"
                                  :search-input.sync="email"
                                  @change="getFilterData"
                                ></v-autocomplete>
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            style="position: relative"
                            v-if="checkTableColumnVisibility('age')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Age</div>
                              <div class="search_column">
                                <v-select
                                  v-model="searchParams.age"
                                  :items="AgeRange"
                                  @change="ageChange"
                                  label="Age"
                                  ref="ageRef"
                                  solo
                                >
                                  <template v-slot:append-item>
                                    <v-divider class="mb-2"></v-divider>
                                    <div style="width: 150px">
                                      <v-list-item>
                                        <v-text-field
                                          label="Start Age"
                                          dense
                                          v-model="fromAge"
                                          append-icon="mdi-keyboard_arrow_down"
                                        ></v-text-field>
                                      </v-list-item>
                                      <v-list-item>
                                        <v-text-field
                                          label="End Age"
                                          dense
                                          v-model="toAge"
                                          append-icon="mdi-keyboard_arrow_down"
                                        ></v-text-field>
                                      </v-list-item>
                                      <v-list-item>
                                        <v-btn
                                          small
                                          color="primary"
                                          @click="ageOkClick"
                                          >Ok</v-btn
                                        >
                                      </v-list-item>
                                    </div>
                                  </template>
                                </v-select>
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('gender')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Gender</div>
                              <div class="search_column">
                                <v-autocomplete
                                  v-model="searchParams.gender"
                                  solo
                                  filled
                                  label="Gender"
                                  :items="['All', 'Male', 'Female']"
                                  @change="getFilterData"
                                ></v-autocomplete>
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('country')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Country</div>
                              <div class="search_column">
                                <v-autocomplete
                                  solo
                                  filled
                                  label="Country"
                                  v-model="searchParams.country_id"
                                  item-value="id"
                                  item-text="name"
                                  :items="['All', ...countries]"
                                  @change="getFilterData"
                                ></v-autocomplete>
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            style="position: relative"
                            v-if="
                              checkTableColumnVisibility('type') &&
                                global == true
                            "
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Type</div>
                              <div class="search_column">
                                <v-select
                                  v-model="searchParams.product_type_id"
                                  label="Type"
                                  :items="[
                                    { id: null, name: 'All' },
                                    ...productTypes,
                                  ]"
                                  item-text="name"
                                  item-value="id"
                                  solo
                                  filled
                                  @change="searchData"
                                ></v-select>
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('service')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Service</div>
                              <div class="search_column">
                                <v-select
                                  v-model="searchParams.services"
                                  label="Service"
                                  :items="venueServices"
                                  item-text="name"
                                  item-value="venue_service_id"
                                  multiple
                                  solo
                                  filled
                                  @change="searchData"
                                >
                                  <template
                                    v-if="
                                      searchParams.services.length ==
                                        venueServices.length
                                    "
                                    v-slot:selection="{ index }"
                                  >
                                    <span
                                      v-if="index == 0"
                                      class="ellipsis-small"
                                      >All Services</span
                                    >
                                  </template>
                                  <template
                                    v-else
                                    v-slot:selection="{ item, index }"
                                  >
                                    <span v-if="index == 0">
                                      {{ item.name }}
                                    </span>
                                    <span v-if="index == 1"> , ... </span>
                                  </template>

                                  <template v-slot:prepend-item>
                                    <v-list-item
                                      ripple
                                      @click="
                                        toggle('venueServices', 'services', 1)
                                      "
                                    >
                                      <v-list-item-action>
                                        <v-icon
                                          :color="
                                            searchParams.services.length > 0
                                              ? 'indigo darken-4'
                                              : ''
                                          "
                                          >{{
                                            getIcon(
                                              "venueServices",
                                              "services",
                                              1
                                            )
                                          }}</v-icon
                                        >
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          >All</v-list-item-title
                                        >
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-select>
                              </div>
                            </div>
                          </th>

                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('title')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">
                                {{
                                  searchParams.type != null
                                    ? searchParams.type
                                    : "Activity"
                                }}
                                <br />Name
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('product_name')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">
                                Product Name
                              </div>
                              <div class="search_column">
                                <v-autocomplete
                                  solo
                                  filled
                                  label="Product Name"
                                  v-model="searchParams.product_id"
                                  item-value="product_id"
                                  item-text="product_name"
                                  :items="product_names"
                                  :loading="isLoading"
                                  :search-input.sync="product_name"
                                  @change="getFilterData"
                                ></v-autocomplete>
                              </div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('price')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Price</div>
                              <div class="search_column">
                                <v-menu
                                  v-model="priceMenu"
                                  :close-on-content-click="false"
                                  :nudge-width="50"
                                  max-width="150"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      label="Price"
                                      solo
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-card width="150">
                                    <v-card-text>
                                      <v-text-field
                                        dense
                                        v-model="searchParams.from_amount"
                                        label="Price From"
                                      ></v-text-field>
                                      <v-text-field
                                        dense
                                        v-model="searchParams.to_amount"
                                        label="Price To"
                                      ></v-text-field>
                                    </v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        @click="
                                          () => {
                                            searchParams.from_amount = null;
                                            searchParams.to_amount = null;
                                            priceMenu = false;
                                            searchData();
                                          }
                                        "
                                        >Clear</v-btn
                                      >
                                      <v-btn
                                        color="primary"
                                        @click="
                                          () => {
                                            priceMenu = false;
                                            searchData();
                                          }
                                        "
                                        >Filter</v-btn
                                      >
                                    </v-card-actions>
                                  </v-card>
                                </v-menu>
                              </div>
                            </div>
                          </th>
                          <!--
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('method')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">
                                Payment Method
                              </div>


                              <div class="search_column">
                                <v-select
                                  v-model="searchParams.payment_method"
                                  label="Payment Methods"
                                  :items="
                                    paymentMethods.concat(
                                      paymentMethodFromOnline
                                    )
                                  "
                                  item-text="name"
                                  item-value="id"
                                  multiple
                                  solo
                                  filled
                                  @change="searchData"
                                >
                                  <template
                                    v-if="
                                      searchParams.payment_method.length ==
                                        paymentMethods.length
                                    "
                                    v-slot:selection="{ index }"
                                  >
                                    <span v-if="index == 0" class="ellipsis"
                                      >All Payment Methods</span
                                    >
                                  </template>
                                  <template
                                    v-else
                                    v-slot:selection="{ item, index }"
                                  >
                                    <span v-if="index == 0">
                                      {{ item.name }}
                                    </span>
                                    <span v-if="index == 1"> , ... </span>
                                  </template>

                                  <template v-slot:prepend-item>
                                    <v-list-item
                                      ripple
                                      @click="
                                        toggle(
                                          'paymentMethods',
                                          'payment_method',
                                          1
                                        )
                                      "
                                    >
                                      <v-list-item-action>
                                        <v-icon
                                          :color="
                                            searchParams.payment_method.length >
                                            0
                                              ? 'indigo darken-4'
                                              : ''
                                          "
                                          >{{
                                            getIcon(
                                              "paymentMethods",
                                              "payment_method",
                                              1
                                            )
                                          }}</v-icon
                                        >
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          >All</v-list-item-title
                                        >
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-select>
                              </div>
                            </div>
                          </th> -->
                          <!-- Transaction status -->
                          <th
                            class="md-table-head"
                            style="position: relative; min-width: 155px"
                            v-if="checkTableColumnVisibility('transaction')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">
                                Transaction Status
                              </div>

                              <div class="search_column" v-if="logClass">
                                <v-select
                                  v-model="searchParams.status_id"
                                  label="Status"
                                  :items="statuses"
                                  item-text="name"
                                  item-value="id"
                                  multiple
                                  solo
                                  filled
                                  @change="searchData"
                                >
                                  <template
                                    v-if="
                                      searchParams.status_id != null &&
                                        searchParams.status_id.length ==
                                          statuses.length
                                    "
                                    v-slot:selection="{ index }"
                                  >
                                    <span
                                      v-if="index == 0"
                                      class="ellipsis-small"
                                      >All Status</span
                                    >
                                  </template>
                                  <template
                                    v-else
                                    v-slot:selection="{ item, index }"
                                  >
                                    <span v-if="index == 0">
                                      {{ item.name }}
                                    </span>
                                    <span v-if="index == 1"> , ... </span>
                                  </template>

                                  <template v-slot:prepend-item>
                                    <v-list-item
                                      ripple
                                      @click="
                                        toggle('statuses', 'status_id', 1)
                                      "
                                    >
                                      <v-list-item-action>
                                        <v-icon
                                          :color="
                                            searchParams.status_id != null &&
                                            searchParams.status_id.length > 0
                                              ? 'indigo darken-4'
                                              : ''
                                          "
                                          >{{
                                            getIcon("statuses", "status_id", 1)
                                          }}</v-icon
                                        >
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          >All</v-list-item-title
                                        >
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-select>
                              </div>
                            </div>
                          </th>
                          <!-- order status -->
                          <th
                            class="md-table-head"
                            style="position: relative; min-width: 155px"
                            v-if="checkTableColumnVisibility('transaction')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Status</div>

                              <div class="search_column" v-if="logClass">
                                <v-select
                                  v-model="searchParams.order_status_id"
                                  label="Status"
                                  :items="orderStatuses"
                                  item-text="name"
                                  item-value="id"
                                  multiple
                                  solo
                                  filled
                                  @change="searchData"
                                >
                                  <template
                                    v-if="
                                      searchParams.order_status_id != null &&
                                        searchParams.order_status_id.length ==
                                          statuses.length
                                    "
                                    v-slot:selection="{ index }"
                                  >
                                    <span
                                      v-if="index == 0"
                                      class="ellipsis-small"
                                      >All Status</span
                                    >
                                  </template>
                                  <template
                                    v-else
                                    v-slot:selection="{ item, index }"
                                  >
                                    <span v-if="index == 0">
                                      {{ item.name }}
                                    </span>
                                    <span v-if="index == 1"> , ... </span>
                                  </template>

                                  <template v-slot:prepend-item>
                                    <v-list-item
                                      ripple
                                      @click="
                                        toggle(
                                          'orderStatuses',
                                          'order_status_id',
                                          1
                                        )
                                      "
                                    >
                                      <v-list-item-action>
                                        <v-icon
                                          :color="
                                            searchParams.order_status_id !=
                                              null &&
                                            searchParams.order_status_id
                                              .length > 0
                                              ? 'indigo darken-4'
                                              : ''
                                          "
                                          >{{
                                            getIcon(
                                              "orderStatuses",
                                              "order_status_id",
                                              1
                                            )
                                          }}</v-icon
                                        >
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          >All</v-list-item-title
                                        >
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-select>
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Attendance</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('reciept')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Bill</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('ticket')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Ticket</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="
                              checkTableColumnVisibility('schedule') && !eventId
                            "
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Details</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="
                              creditClass &&
                                checkTableColumnVisibility('schedule')
                            "
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Action</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            v-if="checkTableColumnVisibility('order_notes')"
                          >
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Notes</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          class="md-table-row"
                          v-for="(data, index) in logDatas"
                          :key="data.id"
                        >
                          <td class="md-table-cell" v-if="logClass">
                            <div
                              class="md-table-cell-container md-ripple md-disabled"
                            >
                              <v-checkbox
                                style="margin-top: 0px"
                                :disabled="
                                  data.invoice_generated === 1 ||
                                    data.status_id !== 5 ||
                                    data.order_status_id !== 11
                                "
                                :indeterminate="
                                  data.invoice_generated === 1 ||
                                    data.status_id !== 5 ||
                                    data.order_status_id !== 11
                                "
                                v-model="data.isSettle"
                                color="#66c8c8"
                                :value="data.isSettle"
                                @change="checkedOrder"
                                hide-details
                              ></v-checkbox>
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('timestamp')"
                          >
                            <div class="md-table-cell-container">
                              {{ data.timestamp | timeStamp }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              {{ data.invoice_seq_no }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('date')"
                          >
                            <div class="md-table-cell-container">
                              <div class="">
                                {{ data.order_date | dayFormat }}
                              </div>

                              <!-- <div class="book_time_log">
                                {{ data.start_time.substring(0, 5) }} -
                                {{ data.end_time.substr(0, 5) }}
                              </div>-->
                            </div>
                          </td>

                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('reason')"
                          >
                            <div class="md-table-cell-container">
                              {{ data.reason || "--" }}
                            </div>
                          </td>

                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('customer_type')"
                          >
                            <div class="md-table-cell-container">
                              {{ data.customer_type || "NA" }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('name')"
                          >
                            <div class="md-table-cell-container" style="max-width:200px; overflow: hidden; text-overflow: ellipsis;">
                              <router-link
                                v-if="
                                  checkReadPermission(
                                    $modules.clients.customers.id
                                  ) && data.customer_id
                                "
                                :to="`/clients/customers/` + data.customer_id"
                                >{{ data.first_name }}
                                {{ data.last_name }}</router-link
                              >
                              <span  v-else-if="data.customer_id" class="text_ellipse text-neon text-base pointer font-bold "  @click="showUserModel(data.customer_id)">{{ data.first_name }} {{ data.last_name }} </span>


                              <span v-else>Anonymous</span>
                            </div>
                          </td>

                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('mobile')"
                          >
                            <div class="md-table-cell-container">
                              {{ data.mobile || "NA" }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('email')"
                          >
                            <div class="md-table-cell-container">
                              {{ data.email || "NA" }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('age')"
                          >
                            <div class="md-table-cell-container">
                              {{ data.age || "NA" }}
                            </div>
                          </td>

                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('country')"
                          >
                            <div class="md-table-cell-container">
                              {{ data.country || "NA" }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('type') && global"
                          >
                            <div class="md-table-cell-container">
                              {{ data.product_type || "--" }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('service')"
                          >
                            <div class="md-table-cell-container">
                              {{ data.service_name || "--" }}
                            </div>
                          </td>

                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('title')"
                          >
                            <div class="md-table-cell-container">
                              {{ data.title || "NA" }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('product_name')"
                          >
                            <div
                              v-if="data.product_name"
                              class="md-table-cell-container product-names-div"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    {{
                                      getSingleProductName(data.product_name)
                                    }}
                                  </span>
                                </template>
                                <span
                                  class="d-block product-name"
                                  v-for="(product_name,
                                  index) in data.product_name.split(',')"
                                  :key="index"
                                  >{{ product_name }}</span
                                >
                              </v-tooltip>
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('price')"
                          >
                            <div
                              class="md-table-cell-container text-capitalize"
                            >
                              {{ Number(Math.abs(data.total)) | toCurrency }}
                            </div>
                          </td>
                          <!-- <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('method')"
                          >
                            <div
                              class="md-table-cell-container text-capitalize"
                            >
                              {{
                                data.status != "Refund"
                                  ? data.payment_method_name
                                  : data.refund_payment_method_name || "NA"
                              }}
                              {{
                                data.card_number
                                  ? "- (" +
                                    data.card_type +
                                    " " +
                                    data.card_number.slice(-4) +
                                    ")"
                                  : ""
                              }}
                            </div>
                          </td> -->
                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('transaction')"
                          >
                            <div
                              class="md-table-cell-container text-capitalize"
                            >
                              {{ data.status || "NA" }}
                              <!-- <span
                                v-if="
                                  data.status == 'Refund' &&
                                  data.refund_payment_method_name
                                "
                                >({{ data.refund_payment_method_name }})</span
                              > -->
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('transaction')"
                          >
                            <div
                              class="md-table-cell-container text-capitalize"
                            >
                              {{ data.order_status_name || "NA" }}
                            </div>
                          </td>
                          <td class="md-table-cell" v-if="!cancellationClass">
                            <div class="md-table-cell-container">
                              <v-btn
                                v-if="
                                  data.facility_booking_id != null &&
                                    data.is_capacity == 1
                                "
                                normal
                                small
                                @click="openParticipants(data)"
                              >
                                <span>View</span>
                              </v-btn>
                              <span v-else>NA</span>
                            </div>
                          </td>
                          <td class="md-table-cell" v-if="!cancellationClass">
                            <div class="md-table-cell-container">
                              <v-btn
                                normal
                                small
                                @click="getOrderDetails(data)"
                              >
                                <span
                                  v-if="
                                    data.status == 'Unpaid' ||
                                      (data.paid_at == null &&
                                        !['Rescheduled'].includes(
                                          data.order_status_name
                                        ))
                                  "
                                  >Invoice</span
                                >
                                <span v-else-if="data.status == 'Refund'"
                                  >Refund Bill</span
                                >
                                <span v-else>Receipt</span>
                              </v-btn>
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('ticket')"
                          >
                            <div class="md-table-cell-container">
                              <v-btn normal small @click="viewTicket(data)" v-if="typeof data.event_booking_id === 'undefined' || data.event_booking_id === null || data.is_booking_approved == 1  ">
                                Ticket
                              </v-btn>
                              <div class="d-flex align-center" v-else-if="data.order_status_id == 11">
                                <v-btn color="red" small class="mr-2" @click="dialogueConfirm(data,'reject')">
                                  Reject
                                </v-btn>
                                <v-btn color="success" small @click="dialogueConfirm(data,'approve')">
                                  Approve
                                </v-btn>
                              </div>
                              <template v-else>
                                NA
                              </template>
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="
                              checkTableColumnVisibility('schedule') && !eventId
                            "
                          >
                            <div class="md-table-cell-container">
                              <v-btn
                                normal
                                small
                                @click="gotoDetails(index)"
                                v-if="
                                  data.status != 'Refund' &&
                                    data.status != 'Cancelled' &&
                                    data.status != 'Void' &&
                                    data.order_status_name !=
                                      'Reservation Cancel' &&
                                    data.order_status_name != 'Cancelled' &&
                                    data.product_type != 'Credit Settlement' &&
                                    data.order_status_name != 'Refund' &&
                                    data.order_status_name != 'Cancelled' &&
                                    data.order_status_name !=
                                      'Partial Refund' &&
                                    data.order_status_name != 'Partial Cancel'
                                "
                                >Details</v-btn
                              >
                              <span v-else>NA</span>
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="
                              creditClass &&
                                checkTableColumnVisibility('schedule')
                            "
                          >
                            <div class="md-table-cell-container">
                              <v-btn
                                normal
                                small
                                v-if="data.status != 'Refund'"
                                @click="creditSettle(data.order_id)"
                                >Settle</v-btn
                              >
                              <span v-if="data.status == 'Refund'">NA</span>
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            v-if="checkTableColumnVisibility('order_notes')"
                          >
                            <div class="md-table-cell-container">
                              {{ data.order_notes || "--" }}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <v-row>
                  <v-col cols="4"></v-col>
                  <v-col cols="4">
                    <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
                  </v-col>
                  <v-col class="d-flex align-center justify-end" cols="4">
                    <div class="d-flex align-center justify-end text-sm gap-x-2">
                <span class="">
                  Result
                </span>
                      <div style="width: 80px">


                        <v-select
                            v-model="perPage"
                            :items="[10, 15, 25, 50]"
                            :menu-props="{ bottom: true, offsetY: true }"
                            class="q-autocomplete text-sm"
                            flat
                            hide-details
                            solo
                            @change="searchData"
                        ></v-select>
                      </div>
                      <span>Per Page</span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <order-details
      :id="orderId"
      :ids="orderIds"
      :invId="invoiceModel.invoiceId"
      :invIds="invoiceModel.invoiceIds"
      :log="true"
      :eInvId="eInvoiceModel.invoiceId"
      :payInvId="paymentModel.invoiceId"
      :creditInvId="creditInvoiceId"
      @openMainOrderDetails="openMainOrderDetails"
      @closeMainComponent="closeMainComponent"
      @invoiceReload="invoiceReload"
      @close="closeMainComponent"
    ></order-details>
    <credit-order-payment
      :id="creditOrderId"
      @close="creditOrderId = null"
      @payed="(creditOrderId = null), searchData()"
      @cancel="(creditOrderId = null), searchData()"
    ></credit-order-payment>
    <attendance
      @open-booking="openBooking"
      v-bind="participant"
      @close="participant.showParticipants = false"
      :refresh="refresh"
    ></attendance>
    <ticket-model v-bind="ticketData" @close="ticketData = { id: null }" />
    <customer-model v-bind="userModel" @close="userModel.userID = null" />
    <!-- <InvoiceModel
      v-if="invoiceModel.invoiceId"
      v-bind="invoiceModel"
      :invoiceData="invoiceData"
      @openMainOrderDetails="openMainOrderDetails"
      @openPayInvoice="openPayInvoice"
      @close="closeInvoiceModel"
    /> -->
    <!-- <PaymentModelNew
      v-if="paymentModel.invoiceId"
      v-bind="paymentModel"
      :payInvoiceData="payInvoiceData"
      @close="closePaymentModel"
      @payed="openInvoice"
    /> -->

    <v-dialog v-model="showTicketPopup" persistent max-width="400">
      <v-card class="kiosk-ticket-dialog" id="tp">
        <v-card-title class="">
          <h3 class="success-heading" text-center>
            Ticket{{ tickets.length > 1 ? "s" : "" }}
          </h3>
        </v-card-title>
        <v-card-text class="card-text text-center fixed">
          <div subheader v-if="tickets.length > 0">
            <div
              class="row ticket-box"
              v-for="(item, index) in tickets"
              :key="index"
            >
              <div class="col-sm-4">
                <div class="subhead pt-10">Guest {{ index + 1 }}:</div>
              </div>
              <div class="col-sm-8">
                <div class="subhead pt-10">
                  {{ item.customer.first_name }} {{ item.customer.last_name }}
                </div>
              </div>
              <div class="col-sm-4">
                Status:
              </div>
              <div class="col-sm-8">
                {{ item.status_id == 1 ? "Enabled" : "Disabled" }}
              </div>
              <div class="col-sm-12">
                <div class="subhead font-14">{{ item.ticket_code }}<br /></div>
                <div class="subhead qr-div">
                  <qr-code :text="item.ticket_qr_code"></qr-code>
                </div>
              </div>
              <div class="col-sm-12">
                <v-spacer></v-spacer>
                <v-btn
                  :style="{ color: item.status_id == 1 ? 'red' : 'green' }"
                  @click="toggleTicketStatus(item.id,item.type)"
                  >{{ item.status_id == 1 ? "Disable" : "Enable" }}</v-btn
                >
                <v-spacer></v-spacer>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="col-4">
            <v-btn
              class="close-btn"
              color="darken-2"
              text
              @click="(showTicketPopup = false), (currentOrder = null)"
            >
              Close
            </v-btn>
          </div>

          <div class="col-4"></div>
          <div class="col-4">
            <v-btn color="#00b0af" dark @click="emailTickets(tickets[0].type)">
              Email
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>
<!-- console.log(id), console.log('logs page'), (orderId = id); -->
<script>
import moment from "moment";
import { mapGetters } from "vuex";
// import SaleTopTab from "../../components/Sale/SaleTopTab.vue";
import OrderDetails from "../../components/Order/OrderDetails.vue";
import CreditOrderPayment from "../../components/Order/CreditOrderPayment.vue";
import TicketModel from "../../components/Ticket/TicketModel.vue";
import CustomerModel from "./../Clients/Customer/CustomerModel";
import Attendance from "./Attendance";
// import InvoiceModel from "../../components/Invoice/InvoiceModel.vue";
// import PaymentModelNew from "@/components/Invoice/PaymentModelNew.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import FilterIcon from "@/assets/images/misc/filter-search.svg";
import EventIcon from "@/assets/images/misc/calendar.svg";
import FinanceTopBar from "@/components/Sale/FinanceTopBar.vue";
export default {
  props: {
    global: {
      type: Boolean,
      default: true,
    },
    eventId: {
      type: Number,
      default: null,
    },
    promotionId: {
      type: Number,
      default: null,
    },
    exportPermission: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FinanceTopBar,
    SvgIcon,
    OrderDetails,
    TicketModel,
    CustomerModel,
    CreditOrderPayment,
    Attendance,
    // InvoiceModel,
    // PaymentModelNew,
    // SaleTopTab,
    ExportIcon,
    EventIcon,
    FilterIcon
  },
  data() {
    return {
      invoiceAmount: 0,
      selectedOrders: [],
      invoiceData: {},
      items: [],
      sports: [],
      datas: [],
      logDatas: [],
      searchFlag: false,
      date1: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      searchParams: {
        services: [],
        payment_method: [],
        timestamp: [],
        status_id: null,
        start_date: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      },
      menu1: false,
      menu2: false,
      flag: false,
      getGroundbyID: [],
      timeDuration: [
        { name: "All", title: "All" },
        { name: "week", title: "This Week" },
        { name: "year", title: "This Year" },
        { name: "month", title: "This Month" },
        { name: "custom", title: "Custom Duration" },
      ],
      orderBy: "id",
      orderDir: "DESC",
      status_id: null,
      logClass: false,
      creditClass: false,
      refundClass: false,
      cancellationClass: false,
      page: 1,
      totalPages: 1,
      perPage: null,
      fieldData: {},
      btnShow: false,
      receiptData: { id: null },
      ticketData: { id: null },
      product_names: [],
      product_name: null,
      names: [],
      name: null,
      cashierNames: [],
      cashierName: null,
      country_id: null,
      payment_method_id: null,
      phones: [],
      phone: null,
      emails: [],
      email: null,
      isLoading: false,
      datemenu: false,
      duemenu: false,
      AgeRange: ["All", "18-25", "26-30", "31-35"],
      Age: [],
      toAge: null,
      fromAge: null,
      columnData: [],
      columnData_list: [
        { name: "Timestamp", value: "timestamp" },
        { name: "Transaction No", value: "bill_no" },
        { name: "Original Transaction No", value: "original_trans_no" },
        { name: "Cashier name", value: "cashier_name" },
        { name: "Due Date", value: "date" },
        { name: "Customer Type", value: "customer_type" },
        { name: "Customer Name", value: "name" },
        { name: "Customer Mobile", value: "mobile" },
        { name: "Customer Email", value: "email" },
        { name: "Booking Type", value: "type" },
        { name: "Service", value: "service" },
        { name: "Product Name", value: "product_name" },

        // { name: "Event/Class Name", value: "title" },
        // { name: 'Location', value: 'location' },
        { name: "Price", value: "price" },
        // { name: "Payment Method", value: "method" },
        { name: "Transation Status", value: "transaction" },
        { name: "Receipt", value: "reciept" },
        { name: "Ticket", value: "ticket" },
        { name: "Schedule Details", value: "schedule" },
        { name: "Notes", value: "order_notes" },
      ],
      columns: [],
      columns_list: [
        "timestamp",
        "bill_no",
        "invoice_no",
        "original_trans_no",
        "cashier_name",
        "date",
        "name",
        "type",
        "service",
        "product_name",
        "price",
        // "method",
        "reciept",
        "ticket",
        "schedule",
        "status",
        "transaction",
      ],
      //  paymentMethod: ["Cash", "Card", "Voucher", "Credit", "Complementary"],
      booking_details: {},
      priceMenu: false,
      orderId: null,
      creditOrderId: null,
      checkPermission: false,
      userModel: { userID: null, type: "details" },
      invoiceModel: { invoiceId: null, type: "details", orderIds: [] },
      showParticipants: false,
      participant: {},
      refresh: false,
      paymentMethodFromOnline: [
        {
          id: 15,
          name: "Online",
          status_id: 1,
          type: "online",
        },
        {
          id: 16,
          name: "Krews",
          status_id: 1,
          type: "online",
        },
        {
          id: 18,
          name: "Kiosk",
          status_id: 1,
          type: "online",
        },
      ],
      orderIds: null,
      tickets: [],
      showTicketPopup: false,
      currentOrder: null,
      paymentModel: { invoiceId: null, type: "details", orderIds: [] },
      payInvoiceData: {},
      eInvoiceModel: { invoiceId: null, type: "details", orderIds: [] },
      creditInvoiceId: null,
      confirmModel:{
        id:null,
        title:null,
        description:null,
      }
    };
  },
  watch: {
    page() {
      this.searchData();
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    cashierName(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("cashier_name", val);
    },
    phone(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("mobile", val);
    },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
    product_name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.productFilterSearch("product_name", val);
    },

    $route(to) {
      console.log("to name log");
      console.log(to.name);
      // if (to.name == "Credits") {
      //   this.logClass = false;
      //   this.creditClass = true;
      //   this.refundClass = false;
      //   this.cancellationClass = false;
      //   this.status_id = 7;
      //   this.checkPermission = this.checkExportPermission(
      //     this.$modules.sales.credits.slug
      //   );
      //   this.searchData();
      //   this.columns = this.columns_list.filter((e) => e !== "reason");
      //   this.columns.push("schedule");
      //   this.columnData = this.columnData_list.filter(
      //     (e) => e.value !== "reason"
      //   );
      // } else
      if (to.name == "Refunds") {
        console.log("refunds");
        this.logClass = false;
        this.creditClass = false;
        this.refundClass = true;
        this.cancellationClass = false;
        this.status_id = 8;
        this.searchData();
        this.checkPermission = this.checkExportPermission(
          this.$modules.sales.refunds.slug
        );
        this.columns = this.columns_list.filter((e) => e !== "schedule");
        this.columns.push("reason");
        console.log(this.columnData_list);
        this.columnData = this.columnData_list;
      } else if (to.name == "Cancellations") {
        this.logClass = false;
        this.creditClass = false;
        this.refundClass = false;
        this.cancellationClass = true;
        this.status_id = 13;
        this.searchData();
        this.checkPermission = this.checkExportPermission(
          this.$modules.sales.logs.slug
        );
        this.columns = [];
        this.columns = this.columns_list.filter(
          (e) =>
            e === "timestamp" ||
            e === "bill_no" ||
            e === "invoice_no" ||
            e === "original_trans_no" ||
            e === "cashier_name" ||
            e === "date" ||
            e === "name" ||
            e === "type" ||
            e === "service" ||
            e === "price" ||
            e === "status"
        );
        this.columnData = [];
        this.columnData = this.columnData_list.filter(
          (e) =>
            e.value === "timestamp" ||
            e.value === "bill_no" ||
            e.value === "invoice_no" ||
            e.value === "original_trans_no" ||
            e.value === "cashier_name" ||
            e.value === "date" ||
            e.value === "name" ||
            e.value === "type" ||
            e.value === "service" ||
            e.value === "price" ||
            e === "status"
        );
      } else {
        this.logClass = true;
        this.creditClass = false;
        this.refundClass = false;
        this.cancellationClass = false;
        this.status_id = [4, 5, 8, 17, 21, 22];
        this.searchData();
        this.checkPermission = this.checkExportPermission(
          this.$modules.sales.logs.slug
        );
        this.columns = [];
        this.columns = this.columns_list.filter(
          (e) => e !== "original_trans_no"
        );
        this.columns.push("schedule");
        this.columnData = [];
        this.columnData = this.columnData_list.filter(
          (e) => e.value !== "original_trans_no"
        );
      }
    },
  },
  computed: {
    ...mapGetters({
      getVenueServices: "getVenueServices",
    }),
    date1Formatted() {
      return moment(this.searchParams.start_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.end_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    venueCode() {
      return this.$store.getters.venueInfo.code.toUpperCase();
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
    paymentMethods() {
      return this.$store.getters.getPaymentMethods.data;
    },
    statuses() {
      return this.$store.getters.getStatuses.data;
    },
    orderStatuses() {
      return this.$store.getters.getOrderStatuses.data;
    },
  },
  mounted() {
    this.productFilterSearch("product_name", "");
    if (this.$router.currentRoute.name == "Logs") {
      this.status_id = [4, 5, 8, 17, 21, 22];
      this.logClass = true;
      this.creditClass = false;
      this.columns = [];
      this.columns = this.columns_list.filter((e) => e !== "original_trans_no");
      this.columns.push("schedule");
      this.columnData = [];
      this.columnData = this.columnData_list.filter(
        (e) => e.value !== "original_trans_no"
      );

      this.checkPermission = this.checkExportPermission(
        this.$modules.sales.logs.slug
      );
    } else if (this.$router.currentRoute.name == "Credits") {
      this.logClass = false;
      this.creditClass = true;
      this.status_id = 7;
      this.columns = this.columns_list.filter((e) => e !== "reason");
      this.columns.push("schedule");
      this.columnData = this.columnData_list.filter(
        (e) => e.value !== "reason"
      );
      this.checkPermission = this.checkExportPermission(
        this.$modules.sales.credits.slug
      );
    } else if (this.$router.currentRoute.name == "Refunds") {
      this.logClass = false;
      this.creditClass = false;
      this.refundClass = true;
      this.cancellationClass = false;
      this.status_id = 8;
      this.checkPermission = this.checkExportPermission(
        this.$modules.sales.refunds.slug
      );
      this.columns = this.columns_list.filter((e) => e !== "schedule");
      this.columns.push("reason");
      this.columnData = this.columnData_list;
    } else if (this.$router.currentRoute.name == "Cancellations") {
      console.log(this.$router.currentRoute.name);
      this.logClass = false;
      this.creditClass = false;
      this.refundClass = false;
      this.cancellationClass = true;
      this.status_id = 13;
      this.searchData();
      this.checkPermission = this.checkExportPermission(
        this.$modules.sales.logs.slug
      );
      this.columns = this.columns_list.filter(
        (e) =>
          e === "timestamp" ||
          e === "bill_no" ||
          e === "invoice_no" ||
          e === "original_trans_no" ||
          e === "cashier_name" ||
          e === "date" ||
          e === "name" ||
          e === "type" ||
          e === "service" ||
          e === "price" ||
          e === "status"
      );

      this.columnData = this.columnData_list.filter(
        (e) =>
          e.value === "timestamp" ||
          e.value === "bill_no" ||
          e.value === "invoice_no" ||
          e.value === "original_trans_no" ||
          e.value === "cashier_name" ||
          e.value === "date" ||
          e.value === "name" ||
          e.value === "type" ||
          e.value === "service" ||
          e.value === "price" ||
          e === "status"
      );
    } else {
      this.columns = [];
      this.columns = this.columns_list.filter((e) => e !== "original_trans_no");
      this.columns.push("schedule");
      this.columnData = [];
      this.columnData = this.columnData_list.filter(
        (e) => e.value !== "original_trans_no"
      );
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
    if (this.$store.getters.getPaymentMethods.status == false) {
      this.$store.dispatch("loadPaymentMethods", "normal");
    }
    if (this.$store.getters.getStatuses.status == false) {
      this.$store.dispatch("loadStatuses").then(() => {});
    } else {
      this.searchParams.status_id = [];
      this.statuses.forEach((element) => {
        if (element.id) {
          this.searchParams.status_id.push(element.id);
        }
      });
      // this.searchData();
    }

    if (this.$store.getters.getOrderStatuses.status == false) {
      this.$store.dispatch("loadOrderStatuses").then(() => {
        this.searchParams.order_status_id = [];
        this.searchData();
      });
    } else {
      this.searchParams.order_status_id = [];
      // console.log("search data 8");
      this.searchData();
    }

    setTimeout(() => {
      this.btnShow = true;
    }, 10);

    if (this.$store.getters.getCardTypes.status == false) {
      this.$store.dispatch("loadCardTypes").then((response) => {
        response.forEach((el) => {
          this.paymentMethodFromOnline.push({
            id: el.id.toString(),
            name: "Card" + " ( " + el.name + " )",
            status_id: 1,
            type: "online",
          });
        });
      });
    } else {
      this.$store.getters.getCardTypes.data.forEach((el) => {
        this.paymentMethodFromOnline.push({
          id: el.id.toString(),
          name: "Card" + " ( " + el.name + " )",
          status_id: 1,
          type: "online",
        });
      });
    }
  },
  methods: {
    invoiceReload(id) {
      this.$http
        .get(`venues/invoices/${id}`)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status) {
            let data = response.data.data;
            this.invoiceData = data;
            this.invoiceModel.invoiceId = data.id;
            this.invoiceModel.orderIds = this.selectedOrders;
          } else {
            this.invoiceModel.invoiceId = null;
            this.invoiceModel.orderIds = [];
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    // openMainOrderDetails(id) {
    //   this.orderId = id;
    // },
    openMainOrderDetails(id) {
      this.invoiceModel.invoiceId = null;
      this.eInvoiceModel.invoiceId = null;
      this.olModel.invoiceId = null;
      this.orderId = null;
      this.orderIds = null;
      this.orderId = id;
    },
    closeMainComponent() {
      this.orderId = null;
      this.orderIds = null;
      this.invoiceModel.invoiceId = null;
      this.invoiceModel.invoiceIds = [];
      this.eInvoiceModel.invoiceId = null;
      this.paymentModel.invoiceId = null;
      this.creditInvoiceId = null;
      this.searchData();
    },
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    toggle(type, field, searchP) {
      this.$nextTick(() => {
        if (searchP) {
          if (
            this.searchParams[field] != null &&
            this.searchParams[field].length == this[type].length
          ) {
            this.searchParams[field] = [];
          } else {
            this.searchParams[field] = this[type].map((x) => x.id);
          }
        } else {
          if (this[field].length == this[type].length) {
            this[field] = [];
          } else {
            this.searchParams[field] = this[type];
          }
        }
        this.searchData();
      });
      this.$forceUpdate();
    },

    getOrderDetails(data) {
      if (data.parent_order_ids) {
        this.orderIds = [data.order_id];
        data.parent_order_ids.split(",").forEach((id) => {
          this.orderIds.push(Number(id));
        });
      } else {
        this.orderId = data.order_id;
      }
    },

    creditSettle(id) {
      this.creditOrderId = id;
    },

    getIcon(type, field, searchP) {
      let icon = "mdi-checkbox-blank-outline";
      if (searchP) {
        if (
          this.searchParams[field] != null &&
          this.searchParams[field].length == this[type].length
        ) {
          icon = "mdi-close-box";
        }
        if (
          this.searchParams[field] != null &&
          this.searchParams[field].length > 0 &&
          this.searchParams[field].length != this[type].length
        )
          icon = "mdi-minus-box";
      } else {
        if (this[field].length == this[type].length) {
          icon = "mdi-close-box";
        }
        if (this[field].length > 0 && this[field].length != this[type].length)
          icon = "mdi-minus-box";
      }
      return icon;
    },
    customerForward(id) {
      this.$router.push(
        {
          name: "CustomersByID",
          params: { id: id },
        },
        () => {}
      );
    },
    ageOkClick() {
      if (this.fromAge != null && this.toAge != null) {
        this.searchParams.from_age = this.fromAge;
        this.searchParams.to_age = this.toAge;
        let customAgeRange = this.fromAge + "-" + this.toAge;
        this.AgeRange.push(customAgeRange);
        this.searchParams.age = customAgeRange;
        this.toAge = null;
        this.fromAge = null;
        this.$refs.ageRef.blur();
        this.searchData();
      }
    },
    ageChange() {
      if (this.searchParams.age != "All") {
        let data = this.searchParams.age.split("-");
        this.searchParams.from_age = data[0];
        this.searchParams.to_age = data[1];
        this.searchData();
      } else {
        this.allAge();
      }
    },
    allAge() {
      delete this.searchParams.from_age;
      delete this.searchParams.to_age;
      delete this.searchParams.age;
      this.searchData();
    },
    filterSearch(type, val) {
      this.isLoading = true;
      this.$http
        .get("venues/customers/filters?field=" + type + "&search=" + val)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "name") {
              this.names = response.data.data;
              this.names.unshift(val);
              this.names.unshift("All");
            } else if (type == "mobile") {
              this.phones = response.data.data;
              this.phones.unshift(val);
              this.phones.unshift("All");
            } else if (type == "email") {
              this.emails = response.data.data;
              this.emails.unshift(val);
              this.emails.unshift("All");
            } else if (type == "cashier_name") {
              this.cashierNames = response.data.data;
              this.cashierNames.unshift(val);
              this.cashierNames.unshift("All");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    productFilterSearch(type, val) {
      this.isLoading = true;
      this.$http
        .get("venues/products/filters?field=" + type + "&search=" + val)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "product_name") {
              this.product_names = response.data.data;
              this.product_names.unshift(val);
              this.product_names.unshift("All");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterData() {
      this.page = 1;
      this.searchData();
    },
    searchData() {
      this.invoiceAmount = 0;
      this.selectedOrders = [];
      this.showLoader("Loading..");
      let url = "";
      if (
        typeof this.$route.name != "undefined" &&
        this.$route.name == "Refunds"
      ) {
        url += "&status=2";
      }

      if (
        this.searchParams.time_intervel != "All" &&
        this.searchParams.time_intervel != null
      ) {
        // this.searchParams.end_date = moment().format("YYYY-MM-DD");
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.end_date = moment().format("YYYY-MM-DD");
          this.searchParams.start_date = moment()
            .startOf("week")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.end_date = moment().format("YYYY-MM-DD");
          this.searchParams.start_date = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.end_date = moment().format("YYYY-MM-DD");
          this.searchParams.start_date = moment()
            .startOf("year")
            .format("YYYY-MM-DD");
        }
        url += "&end_date=" + this.searchParams.end_date;
        url += "&start_date=" + this.searchParams.start_date;
      }
      if (this.searchParams.product_type_id != null) {
        url += `&product_type_id=${this.searchParams.product_type_id}`;
      }
      if (
        this.searchParams.product_id != null &&
        this.searchParams.product_id != "All"
      ) {
        url += `&product_id=${this.searchParams.product_id}`;
      }
      if (
        typeof this.searchParams.reason != "undefined" &&
        this.searchParams.reason.length > 0
      ) {
        url += `&reason=${this.searchParams.reason}`;
      }
      if (
        this.searchParams.services != "All" &&
        this.searchParams.services != null &&
        this.searchParams.services.length > 0
      ) {
        url += `&venue_service_id=${this.searchParams.services}`;
      }
      if (this.searchParams.date == null) {
        this.searchParams.date = null;
      } else if (typeof this.searchParams.date != "undefined") {
        url += "&date=" + this.searchParams.date;
      }
      if (this.searchParams.timestamp == null) {
        delete this.searchParams.timestamp;
      } else if (typeof this.searchParams.timestamp != "undefined") {
        if (this.searchParams.timestamp.length > 1) {
          this.searchParams.timestamp.sort((a, b) => new Date(a) - new Date(b));
        }
        url += "&timestamp=" + this.searchParams.timestamp;
      }
      if (this.searchParams.name == "All") {
        delete this.searchParams.name;
      } else if (this.searchParams.name) {
        url += "&name=" + this.searchParams.name;
      }

      if (this.searchParams.cashier_name == "All") {
        delete this.searchParams.cashier_name;
      } else if (this.searchParams.cashier_name) {
        url += "&cashier_name=" + this.searchParams.cashier_name;
      }

      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (this.searchParams.email) {
        url += "&email=" + this.searchParams.email;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (this.searchParams.mobile) {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.gender == "All") {
        this.searchParams.gender = null;
      } else if (this.searchParams.gender) {
        url += "&gender=" + this.searchParams.gender;
      }
      if (this.searchParams.customer_type == null) {
        delete this.searchParams.customer_type;
      } else if (this.searchParams.customer_type) {
        url += "&customer_type=" + this.searchParams.customer_type;
      }
      if (this.searchParams.country_id == "All") {
        delete this.searchParams.country_id;
      } else if (this.searchParams.country_id) {
        url += "&country_id=" + this.searchParams.country_id;
      }
      if (
        typeof this.searchParams.from_age != "undefined" &&
        typeof this.searchParams.to_age != "undefined"
      ) {
        url +=
          "&from_age=" +
          this.searchParams.from_age +
          "&to_age=" +
          this.searchParams.to_age;
      }
      if (this.searchParams.from_amount) {
        url += "&from_amount=" + this.searchParams.from_amount;
      }
      if (this.searchParams.to_amount) {
        url += "&to_amount=" + this.searchParams.to_amount;
      }
      if (this.searchParams.bill_no) {
        url += "&bill_no=" + this.searchParams.bill_no;
      }
      if (this.searchParams.invoice_no) {
        url += "&invoice_no=" + this.searchParams.invoice_no;
      }

      // if (
      //   this.searchParams.payment_method != "All" &&
      //   this.searchParams.payment_method != null &&
      //   this.searchParams.payment_method.length > 0
      // ) {
      //   let cardTypeId = [];
      //   let paymentMethod = [];
      //   this.searchParams.payment_method.forEach((ex) => {
      //     if (typeof ex == "string") {
      //       cardTypeId.push(Number(ex));
      //     } else {
      //       paymentMethod.push(ex);
      //       if (ex == 6) {
      //         paymentMethod.push(11);
      //       }
      //     }
      //   });

      //   if (this.searchParams.payment_method[0] == 11) {
      //     this.searchParams.payment_method = [];
      //     paymentMethod = [];
      //   }
      //   if (paymentMethod.length) {
      //     url += `&payment_method=${paymentMethod}`;
      //   }

      //   if (cardTypeId.length) {
      //     url += `&card_type_id=${cardTypeId}`;
      //   }
      // }

      if (
        this.searchParams.order_status_id != "All" &&
        this.searchParams.order_status_id != null &&
        this.searchParams.order_status_id.length > 0
      ) {
        url += `&order_status_id=${this.searchParams.order_status_id}`;
      } else {
        this.searchParams.order_status_id = null;
      }

      if (this.eventId > 0) {
        url += "&event_id=" + this.eventId;
      }

      if (this.promotionId > 0) {
        url += "&promotion_id=" + this.promotionId;
      }

      if (this.refundClass) {
        url += "&status_id=8&refund=true";
      } else if (this.cancellationClass) {
        url += "&status_id=13&order_status_id=13,23";
      } else if (
        this.searchParams.status_id != null &&
        this.searchParams.status_id.length > 0 &&
        !this.creditClass
      ) {
        url += "&status_id=" + this.searchParams.status_id;
      } else if (this.status_id != null && !this.creditClass) {
        url += "&status_id=" + this.status_id;
      } else if (this.creditClass === true || this.creditClass === 'true') {
        url += "&credit";
      }

      this.searchParams.page = this.page;
      this.searchParams.per_page = this.perPage != null ? this.perPage : 10;
      this.searchParams.order_by = this.orderBy;
      this.searchParams.order_by_value = this.orderDir;
      this.$http
        .get(
          "venues/sales?page=" +
            this.page +
            "&per_page=" +
            (this.perPage != null ? this.perPage : 10) +
            "&order_by=" +
            this.orderBy +
            "&sort_order=" +
            this.orderDir +
            url
        )
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.logDatas = response.data.data;
            // this.logDatas.foreach((item) => {
            //   if(this.selectedOrders)

            // });
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getInvoiceItems() {
      this.receiptData.items = [];
      this.receiptData.discount = false;
      this.receiptData.booking_id = this.booking_details.booking_id;
      if (this.booking_details.promotion != null) {
        this.receiptData.discount = true;
        this.receiptData.promotion = true;
        this.receiptData.promotion_name = this.booking_details.promotion.promotion_name;
        this.receiptData.promotion_type = `${
          this.booking_details.promotion.benefit_type == "P"
            ? this.booking_details.promotion.benefit_amount + "% Discount"
            : this.booking_details.promotion.benefit_amount +
              this.$store.getters.currencyCode +
              "  OFF"
        }`;
        this.receiptData.original_price = this.booking_details.promotion.original_price;
      }
      if (
        this.booking_details.products != null &&
        this.booking_details.products.length > 0
      ) {
        this.receiptData.items = this.booking_details.products.map((item) => {
          let vat = (item.price * 5) / 100;
          let data = {
            title: item.title,
            quantity: item.quantity,
            price: item.price,
            discount: "",
            discount_amount: "",
            vat_rate: "5%",
            vat_amount: vat,
            total_price: vat + parseFloat(item.price),
          };
          if (
            this.booking_details.promotion != null &&
            item.original_price != null &&
            this.booking_details.promotion.benefit_type == "P"
          ) {
            data.discount =
              item.benefit_type == "P" ? item.benefit_amount + "%" : "";
            data.discount_amount = data.price;
            data.price = item.original_price;
          }
          return data;
        });
      } else {
        if (parseFloat(this.booking_details.tax_amount) == 0) {
          this.receiptData.disable_vat = true;
        }
        let title =
          this.receiptData.title +
          (this.receiptData.type == "EVENTBOOKING"
            ? "(" + this.receiptData.ticket_type + ")"
            : "");
        let data = {
          title: title,
          quantity: this.booking_details.total_quanity,
          price: parseFloat(this.booking_details.price),
          discount: 0,
          discount_amount: 0,
          vat_rate: parseFloat(this.booking_details.tax_amount) > 0 ? "5%" : 0,
          vat_amount: parseFloat(this.booking_details.tax_amount),
          total_price: parseFloat(this.booking_details.total_price),
        };
        if (
          this.booking_details.promotion != null &&
          this.booking_details.promotion.benefit_type == "P"
        ) {
          data.discount = `${
            this.booking_details.promotion.benefit_type == "P"
              ? this.booking_details.promotion.benefit_amount + "% Discount"
              : this.booking_details.promotion.benefit_amount +
                this.$store.getters.currencyCode +
                "  OFF"
          }`;
          data.discount_amount = this.booking_details.promotion.promotion_price;
          data.price = this.booking_details.promotion.original_price;
        }
        this.receiptData.items.push(data);
      }
    },
    viewTickets(data, type) {
      this.showLoader("Generating..");
      this.$http
        .get(`venues/orders/booking-tickets/${data.order_id}?type=${type}`)
        .then((response) => {
          if (response.status == 200) {
            this.tickets = response.data.data;
            this.showTicketPopup = true;
            this.hideLoader();
          }
        })
        .catch((error) => {
          console.log(error)
          this.hideLoader();
          this.ticketData = {};
          this.ticketData.timestamp = data.timestamp;
          this.ticketData.order_date = data.order_date;
          this.ticketData.start_time = data.start_time;
          this.ticketData.end_time = data.end_time;
          this.ticketData.type = data.product_type;
          this.ticketData.service = data.service_name;
          this.ticketData.id = String(data.order_id);
          // this.errorChecker(error);
        });
    },
    toggleTicketStatus(ticket_id,type='facility') {
      this.showLoader("Generating..");
      this.$http
        .get(`venues/orders/booking-tickets/toggle?id=${ticket_id}&type=${type}`)
        .then((response) => {
          if (response.status == 200) {
            this.tickets = response.data.data;
            this.showSuccess("Ticket updated successfully");
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    viewTicket(data) {
      if (['Facility','Event'].includes(data.product_type)) {
        this.currentOrder = data.order_id;
        this.viewTickets(data, data.product_type);
      } else {
        this.ticketData = {};
        this.ticketData.timestamp = data.timestamp;
        this.ticketData.order_date = data.order_date;
        this.ticketData.start_time = data.start_time;
        this.ticketData.end_time = data.end_time;
        this.ticketData.type = data.product_type;
        this.ticketData.service = data.service_name;
        this.ticketData.id = String(data.order_id);
      }
    },
    emailTickets(type) {
      this.showLoader("Generating..");
      let typeQuery = '';
      if (type){
        typeQuery = `&type=${type}`;
      }
      this.$http
        .get(
          `venues/orders/booking-tickets/email?order_id=${this.currentOrder}${typeQuery}`
        )
        .then((response) => {
          if (response.status == 200) {
            this.showSuccess("Ticket send successfully");
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    changeFxn() {
      if (this.searchParams.time_intervel == "custom") {
        this.searchParams.start_date = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.searchParams.end_date = moment().format("YYYY-MM-DD");
        this.flag = true;
      } else {
        this.flag = false;
        this.searchData();
      }
    },
    sortColumn(type) {
      if (type == this.orderBy) {
        this.orderDir = this.orderDir == "ASC" ? "DESC" : "ASC";
      } else {
        this.orderDir = "DESC";
      }
      this.orderBy = type;
      this.searchData();
    },
    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },
    gotoDetails(index) {
      let data = this.logDatas[index];
      let venue_service = this.venueServices.find(
        (x) => x.venue_service_id == data.venue_service_id
      );
      if (data.product_type == "Voucher") {
        this.$router.push({
          name: "VoucherSales",
          params: { data: btoa(data.voucher_id) },
        });
      }
      // else if (data.product_type == "Event") {
      //   this.$router.push({
      //     name: "EventScheduleWithParams",
      //     params: {
      //       data: btoa(
      //         JSON.stringify({
      //           venue_service: venue_service,
      //           date: data.order_date,
      //           order_id: data.order_id,
      //         })
      //       ),
      //     },
      //   });
      // }
      else if (data.product_type == "Event") {
        this.$router.push({
          name: "EventsView",
          params: { data: btoa(data.event_id) },
        });
      } else if (data.product_type == "Trainer") {
        this.$router.push({
          name: "TrainerCustomers",
          params: { data: btoa(data.trainer_id) },
        });
      }else if (data.product_type == "POS" && data.outlet_id && data.outlet_id != 'null') {
        this.$router.push({
          name: "OutletView",
          params: { data: btoa(data.outlet_id) },
        });
      }
      else if (data.product_type == "POS" && !data.outlet_id) {
        this.$router.push({
          name: "PosView",
        });
      }
      else if (data.product_type == "Facility") {
        if (!data.facility_booking_id) {
          this.showError("No Related Booking Found");
          return;
        }
        if (data.facility_booking_open_dated) {
          this.showError("Open dated ticket found");
          return;
        }
        if(data.facility_id){
          this.$router.push({
            name: "ScheduleWithParams",
            params: {
              data: btoa(
                  JSON.stringify({
                    venue_service: venue_service,
                    date: data.order_date,
                    order_id: data.order_id,
                    facility_id:data.facility_id
                  })
              ),
            },
          });
        }else{
          this.$router.push({
            name: "ScheduleWithParams",
            params: {
              data: btoa(
                  JSON.stringify({
                    venue_service: venue_service,
                    date: data.order_date,
                    order_id: data.order_id,
                  })
              ),
            },
          });
        }

      } else if (data.product_type == "Membership") {
        if (data.membership_id) {
          this.$router.push({
            name: "MembershipView",
            params: { data: btoa(data.membership_id) },
          });
        }
      } else if (data.product_type == "Academy") {
        this.$router.push({
          name: "WorkshopView",
          params: { data: btoa(JSON.stringify({ id: data.workshop_id })) },
        });
      }
    },
    salesReportExcelDownload() {
      this.showLoader("Downloading report");
      let url = "";
      if (
        typeof this.$route.name != "undefined" &&
        this.$route.name == "Refunds"
      ) {
        url += "&status=2";
      }

      if (
        this.searchParams.time_intervel != "All" &&
        this.searchParams.time_intervel != null
      ) {
        // this.searchParams.end_date = moment().format("YYYY-MM-DD");
        // if (this.searchParams.time_intervel == "custom") {
        //   this.searchParams.start_date = moment()
        //     .subtract(30, "days")
        //     .format("YYYY-MM-DD");
        //   this.flag = true;
        // }
        // else
        if (this.searchParams.time_intervel == "week") {
          this.searchParams.end_date = moment().format("YYYY-MM-DD");
          this.searchParams.start_date = moment()
            .startOf("week")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.end_date = moment().format("YYYY-MM-DD");
          this.searchParams.start_date = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.end_date = moment().format("YYYY-MM-DD");
          this.searchParams.start_date = moment()
            .startOf("year")
            .format("YYYY-MM-DD");
        }
        url += "&end_date=" + this.searchParams.end_date;
        url += "&start_date=" + this.searchParams.start_date;
      }
      if (this.searchParams.product_type_id != null) {
        url += `&product_type_id=${this.searchParams.product_type_id}`;
      }
      if (
        typeof this.searchParams.reason != "undefined" &&
        this.searchParams.reason.length > 0
      ) {
        url += `&reason=${this.searchParams.reason}`;
      }
      if (
        this.searchParams.services != "All" &&
        this.searchParams.services != null &&
        this.searchParams.services.length > 0
      ) {
        url += `&venue_service_id=${this.searchParams.services}`;
      }
      if (this.searchParams.date == null) {
        this.searchParams.date = null;
      } else if (typeof this.searchParams.date != "undefined") {
        url += "&date=" + this.searchParams.date;
      }
      if (this.searchParams.timestamp == null) {
        delete this.searchParams.timestamp;
      } else if (typeof this.searchParams.timestamp != "undefined") {
        url += "&timestamp=" + this.searchParams.timestamp;
      }
      if (this.searchParams.name == "All") {
        this.searchParams.name = null;
      } else if (typeof this.searchParams.name != "undefined") {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (typeof this.searchParams.email != "undefined") {
        url += "&email=" + this.searchParams.email;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (typeof this.searchParams.mobile != "undefined") {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.gender == "All") {
        delete this.searchParams.gender;
      } else if (typeof this.searchParams.gender != "undefined") {
        url += "&gender=" + this.searchParams.gender;
      }
      if (this.searchParams.customer_type == null) {
        delete this.searchParams.customer_type;
      } else if (typeof this.searchParams.customer_type != "undefined") {
        url += "&customer_type=" + this.searchParams.customer_type;
      }
      if (this.searchParams.country_id == "All") {
        delete this.searchParams.country_id;
      } else if (typeof this.searchParams.country_id != "undefined") {
        url += "&country_id=" + this.searchParams.country_id;
      }

      if (this.searchParams.cashier_name == "All") {
        delete this.searchParams.cashier_name;
      } else if (this.searchParams.cashier_name) {
        url += "&cashier_name=" + this.searchParams.cashier_name;
      }

      if (
        typeof this.searchParams.from_age != "undefined" &&
        typeof this.searchParams.to_age != "undefined"
      ) {
        url +=
          "&from_age=" +
          this.searchParams.from_age +
          "&to_age=" +
          this.searchParams.to_age;
      }
      if (this.searchParams.from_amount != null) {
        url += "&from_amount=" + this.searchParams.from_amount;
      }
      if (this.searchParams.to_amount != null) {
        url += "&to_amount=" + this.searchParams.to_amount;
      }

      if (this.searchParams.bill_no) {
        url += "&bill_no=" + this.searchParams.bill_no;
      }
      if (this.searchParams.invoice_no) {
        url += "&invoice_no=" + this.searchParams.invoice_no;
      }

      if (this.eventId > 0) {
        url += "&event_id=" + this.eventId;
      }

      if (this.promotionId > 0) {
        url += "&promotion_id=" + this.promotionId;
      }
      // if (typeof this.searchParams.payment_method_id != "undefined") {
      //   url += "&payment_method=" + this.searchParams.payment_method_id;
      // }

      if (
        this.searchParams.payment_method != "All" &&
        this.searchParams.payment_method != null &&
        this.searchParams.payment_method.length > 0
      ) {
        let cardTypeId = [];
        let paymentMethod = [];
        this.searchParams.payment_method.forEach((ex) => {
          if (typeof ex == "string") {
            cardTypeId.push(Number(ex));
          } else {
            paymentMethod.push(ex);
            if (ex == 6) {
              paymentMethod.push(11);
            }
          }
        });

        if (this.searchParams.payment_method[0] == 11) {
          this.searchParams.payment_method = [];
          paymentMethod = [];
        }
        if (paymentMethod.length) {
          url += `&payment_method=${paymentMethod}`;
        }

        if (cardTypeId.length) {
          url += `&card_type_id=${cardTypeId}`;
        }
      }

      if (
        this.searchParams.order_status_id != "All" &&
        this.searchParams.order_status_id != null &&
        this.searchParams.order_status_id.length > 0
      ) {
        url += `&order_status_id=${this.searchParams.order_status_id}`;
      } else {
        this.searchParams.order_status_id = null;
      }
      if (this.refundClass) {
        url += "&status_id=8&refund=true";
      } else if (this.cancellationClass) {
        url += "&status_id=13";
      } else if (this.searchParams.status_id != null && !this.creditClass) {
        url += "&status_id=" + this.searchParams.status_id;
      } else if (this.status_id != null && !this.creditClass) {
        url += "&status_id=" + this.status_id;
      } else if (this.creditClass === true || this.creditClass === 'true') {
        url += "&credit";
      }
      url += "&visible_fields=" + this.columns.join(",");

      this.searchParams.page = this.page;
      this.searchParams.per_page = this.perPage != null ? this.perPage : 10;
      this.searchParams.order_by = this.orderBy;
      this.searchParams.order_by_value = this.orderDir;
      this.$http
        .get(
          "venues/sales/report/download?page=" +
            this.page +
            "&per_page=" +
            (this.perPage != null ? this.perPage : 10) +
            "&order_by=" +
            this.orderBy +
            "&sort_order=" +
            this.orderDir +
            url,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadFile(response, "SalesReport_");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    openParticipants(data) {
      //console.log(data)
      this.participant = {
        showParticipants: true,
        order_id: data.order_id,
      };
    },
    openBooking(data) {
      this.openBookingForm(data);
    },
    openBookingForm(data) {
      this.bookingForm = {
        showBookingForm: true,
        start_time: moment(data.facility_booking_start_time, "hh:mm a").format(
          "HH:mm:ss"
        ),
        end_time: moment(data.facility_booking_end_time, "hh:mm a").format(
          "HH:mm:ss"
        ),
        facility_name: data.facility_name,
        facility_id: data.facility_id,
        date: data.facility_booking_date,
        increment: data.increment,
        venue_service_id: data.venue_service_id,
        service: data.service_name,
        id: data.id != null ? data.id : 0,
        order_id: data.order_id,
      };
    },
    getSingleProductName(data) {
      if (data) {
        let product_name = data.split(",");
        if (product_name.length > 1) {
          return product_name[0] + " ...";
        } else {
          return product_name[0];
        }
      } else {
        return "";
      }
    },
    checkedOrder() {
      let invAmount = 0;
      let selectedOrd = [];
      this.selectedOrders = [];
      this.logDatas.map((item) => {
        if (item.isSettle == true) {
          /** to fixed  */
          let arr = {
            order_id: item.order_id,
            total: parseFloat(item.total),
          };
          invAmount += item.total;
          selectedOrd.push(arr);
        }
      });
      this.selectedOrders = selectedOrd;
      this.invoiceAmount = invAmount;
      console.log(this.selectedOrders);
      // this.$forceUpdate();
    },
    /** invoice functions */
    genrateInvoice() {
      console.log("invoice clicked");

      if (this.selectedOrders.length === 0) {
        this.showError("Select Order");
        return false;
      }
      this.showLoader("Wait ...");
      let formData = new FormData();
      this.selectedOrders.forEach((item, i) => {
        formData.append(`order_ids[${i}]`, item.order_id);
      });
      this.$http
        .post("venues/invoices", formData)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status) {
            let data = response.data.data;
            this.invoiceData = data;
            this.invoiceModel.invoiceId = data.id;
            this.invoiceModel.orderIds = this.selectedOrders;
          } else {
            this.invoiceModel.invoiceId = null;
            this.invoiceModel.orderIds = [];
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    closeInvoiceModel() {
      this.invoiceModel.invoiceId = null;
      this.searchData();
    },
    openPayInvoice(invoice_id) {
      this.showLoader("Loading..");
      this.$store
        .dispatch("loadInvoiceDetails", invoice_id)
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            let data = response.data.data;
            this.paymentModel.invoiceId = data.id;
            this.payInvoiceData = data;
            this.payInvoiceData.invoiceId = data.id;
            this.payInvoiceData.wallet_redeem_amount = 0;
            // console.log(this.paymentModel);
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    openInvoice(invoice_id, type = "view") {
      this.showLoader("Loading..");
      this.$http
        .get("venues/invoices/" + invoice_id)
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            let data = response.data.data;
            if (type == "view") {
              this.invoiceData = data;
              this.invoiceModel.invoiceId = data.id;
              this.invoiceModel.orderIds = [];
              this.paymentModel.invoiceId = null;
            }
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    closePaymentModel() {
      this.paymentModel.invoiceId = null;
      this.searchData();
    },
    approveEventBooking(booking) {
      this.showLoader("Approving...");
      this.$http
          .get(
              `venues/events/bookings/booking-status/${booking.event_booking_id}/approve`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.showSuccess("Booking Approved.");
              this.searchData();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    rejectEventBooking(booking) {
      this.showLoader("Rejecting...");
      this.$http
          .get(
              `venues/events/bookings/booking-status/${booking.event_booking_id}/reject`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.showSuccess("Booking Rejected.");
              this.searchData();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    dialogueConfirm(booking,type) {
      this.confirmModel = {
        id: booking.event_booking_id,
        title: `Do you want to ${type} booking?`,
        description:"By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: type,
        data:{
          booking:booking
        }
      };
    },
    confirmActions(data) {
      if (data?.data?.booking && data.type == "reject") {
        this.rejectEventBooking(data.data.booking);
      } else if(data?.data?.booking && data.type == "approve") {
        this.approveEventBooking(data.data.booking);
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
.book_time_log {
  color: #066a8c;
}
.md-card-header tr th:nth-child(1) {
  text-align: left !important;
}
.search_customer .v-input {
  margin: 0 0px;
}
.headline_ticket {
  background: #000;
  color: #fff;
}
.head {
  text-align: center;
  margin: 0 auto;
}
.headTicket {
  color: gray;
  font-weight: bold;
}
.valueTicket {
  color: #fff;
  border-bottom: 1px solid gray;
}
.sch_btn_class,
.sch_btn_event {
  padding: 0 35px !important;
}
.logTable tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}

.kiosk-confirm-dialog .card-text,
.kiosk-ticket-dialog .card-text {
  font-size: 18px;
}
.kiosk-confirm-dialog button.close-btn,
.kiosk-ticket-dialog button.close-btn {
  text-align: center;
  display: inline-block;
  width: 100%;
  background-color: #f7f7f7;
  font-weight: 600;
  color: #000 !important;
}
.kiosk-confirm-dialog button.confirm-btn {
  text-align: center;
  display: inline-block;
  width: 100%;
  background-color: #86e2ff !important;
  font-weight: 600;
  color: #000 !important;
}
.kiosk-confirm-dialog h3.success-heading,
.kiosk-ticket-dialog h3.success-heading {
  text-align: center;
  margin: 0 auto;
  padding: 12px;
  font-size: 28px;
  border-bottom: 1px solid;
}
.kiosk-ticket-dialog .fixed {
  max-height: 420px;
  overflow-y: scroll;
}
.kiosk-ticket-dialog .ticket-box {
  border: 1px solid #ccc;
  margin: 5px 0px;
}
.kiosk-ticket-dialog .subhead {
  font-weight: 600;
}

.kiosk-ticket-dialog .qr-div {
  margin-left: 30px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.kiosk-ticket-dialog .font-14 {
  font-size: 14px;
}
/* Hide button on iPad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 1) {
  .hide-on-ipad {
    display: none;
  }
}


.text_ellipse {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  overflow: hidden;
}
/* Hide button on iPhone */
@media only screen and (max-device-width: 480px) {
  .hide-on-iphone {
    display: none;
  }
}
</style>