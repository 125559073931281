<script>
export default {
  name: "EventLineup",
  props: {
    lineup: {
      required: true
    }
  },
  data(){
    return {
      readMore:false
    }
  }
}
</script>

<template>
  <div class="bordered rounded-lg card p-4 bg-white" style="height: 100%">
    <div class="d-flex justify-center m-b-3">
      <v-avatar size="120">
        <view-image
            :contain="true"
            :image="lineup.image_path"
        ></view-image>
      </v-avatar>
    </div>

    <h5 class="text-center font-bold text-base">
      {{ lineup.name }}
    </h5>
    <p
        class="pa-2 text-center text-light-black overflow-y-auto"
        style="min-height: 18px; max-height: 180px"
    >
      <template v-if="!readMore && lineup.description && lineup.description.length>135">
        <span>
          {{ lineup.description.slice(0, 135) }} ...
        </span>
        <span class="pointer text-neon text-md-body-2" @click="readMore = true">
          Read more
        </span>
      </template>
      <template v-else>
        {{ lineup.description }}
      </template>
    </p>
  </div>
</template>

<style scoped>

</style>