<template>
  <v-container v-if="event.id" fluid>
    <v-row class="bg-ghost-white p-y-6">
      <v-col lg="3" sm="12">
        <BackButton :handler="goToEvents"/>
      </v-col>
      <v-col lg="6" md="8" sm="8" >
        <div class="d-flex justify-start gap-x-6">
          <div style="max-width: 16rem;max-height: 10rem">
            <view-image :height="160" :image="event.image_path" :width="320" class="rounded-lg" defaultImage="event"/>
          </div>
          <div>
            <h3 class="text-xl m-b-4">
              {{ event.name }}
            </h3>
            <SvgIcon class="gap-x-4 m-b-4 font-medium">
              <template v-slot:icon>
                <CalendarIcon/>
              </template>
              {{ event.start_date | dateformat }} to
              {{ event.end_date | dateformat }}
            </SvgIcon>
            <div class="d-flex gap-x-8">
              <SvgIcon class="gap-x-4 m-b-4">
                <template v-slot:icon>
                  <AttendanceIcon/>
                </template>
                <div>
                  <p class="m-b-0 font-medium">
                    {{ (event.participants||0) + ' / ' + event.total_tickets }}
                  </p>
                  <p class="m-b-0 font-medium text-xs grey--text">
                    Attendance
                  </p>
                </div>
              </SvgIcon>
              <SvgIcon class="gap-x-4 m-b-4" v-if="checkReadPermission($modules.events.sales.slug)">
                <template v-slot:icon>
                  <SalesIcon/>
                </template>
                <div>
                  <p class="m-b-0 font-medium">
                    {{ Number(event.sales) | toCurrency }}
                  </p>
                  <p class="m-b-0 font-medium text-xs grey--text">
                    Sales
                  </p>
                </div>
              </SvgIcon>
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="3" sm="4" >
        <v-menu
            content-class="q-menu"
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="text_capitalize no-hover-effect pa-0 mr-4"
                elevation="0"
                :ripple="false"
                style="background-color: transparent; min-width: 36px !important; "
                v-bind="attrs"
                v-on="on"
                height="48"
            >
              <DotsIcon />
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="checkWritePermission($modules.events.management.slug) && (event.status_id === 1 || event.status_id === 11)" @click="editEvent(event.id)">
              <SvgIcon class="font-medium text-sm gap-x-2" text="Edit">
                <template #icon>
                  <EditIcon height="16" viewBox="0 0 20 20" width="16"/>
                </template>
              </SvgIcon>
            </v-list-item>
            <v-divider class="mb-2"/>
            <v-list-item  v-if="checkDeletePermission($modules.events.management.slug) && event.status_id !== 12"  @click="cancelEvent">
              <SvgIcon class="font-medium text-sm gap-x-2" :class="{'red--text':event.status_id === 1 || event.status_id === 11}" :text="event.status_id === 1 || event.status_id === 11 ? 'Cancel Event' : 'Reactivate Event'">
                <template #icon>
                  <DeleteIcon opacity="1" stroke="red" v-if="event.status_id === 1 || event.status_id === 11"/>
                  <ActivateIcon v-else/>
                </template>
              </SvgIcon>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
            v-if="!event.seat_map_id"
            class="bg-blue text-white text-capitalize "
            @click="openEventForm"
            height="40"
        >
          <AddIcon class="mr-2"/>
          Add Participants
        </v-btn>
      </v-col>
    </v-row>

    <v-card class="shadow-0 mt-5">
      <v-card-actions class="text-center px-0">
        <v-tabs
            v-if="event.status != 0"
            v-model="tab"
            centered
            class="q-tabs-secondary shadow-0 border-bottom"
            icons-and-text
            light
        >

          <v-tab active-class="svg-stroke-neon" href="#details">
            <SvgIcon text="Details">
              <template v-slot:icon>
                <DetailsIcon/>
              </template>
            </SvgIcon>
          </v-tab>
          <v-tab
              v-if="checkReadPermission($modules.events.schedules.slug)"
              active-class="svg-fill-neon"
              href="#schedules"
          >
            <SvgIcon text="Schedules">
              <template v-slot:icon>
                <CalendarCleanIcon/>
              </template>
            </SvgIcon>
          </v-tab>
          <v-tab v-if="checkReadPermission($modules.events.management.slug) && event.seat_map_id" active-class="svg-stroke-neon"
                 href="#maps">
            <SvgIcon text="Seat Map">
              <template v-slot:icon>
                <DetailsIcon/>
              </template>
            </SvgIcon>
          </v-tab>
          <v-tab
              v-if="checkReadPermission($modules.events.sales.slug)"
              active-class="svg-fill-neon"
              href="#sales"
          >
            <SvgIcon text="Sales">
              <template v-slot:icon>
                <WalletIcon/>
              </template>
            </SvgIcon>
          </v-tab>
          <v-tab
              v-if="checkReadPermission($modules.clients.customers.slug)"
              active-class="svg-stroke-neon"
              href="#customer"
          >
            <SvgIcon text="Customer Analysis">
              <template v-slot:icon>
                <AnalyticsIcon/>
              </template>
            </SvgIcon>
          </v-tab>
        </v-tabs>
      </v-card-actions>
      <div class="bg-white pa-4">
        <v-row class="mt-6" no-gutters>
          <v-tabs-items v-model="tab">
            <v-tab-item value="details">
              <event-details v-bind="{ event: event }" @refresh="getEventDetails"></event-details>
            </v-tab-item>
            <v-tab-item value="schedules">
              <event-schedules :event="event" :eventId="event.id"></event-schedules>
            </v-tab-item>
            <v-tab-item value="maps">
              <EventSeatMapDetails
                  :event="event"
                  :eventId="event.id"
                  :mapReload="mapReload"
                  @booked="openOrderCloseBooking"
                  @cancel="getEventDetails"
                  @pay="openOrderCloseBooking"
              />
            </v-tab-item>

            <v-tab-item value="sales"  v-if="checkReadPermission($modules.events.sales.slug)">
              <event-sales
                  :exportPermission="
              checkExportPermission($modules.events.sales.slug)
            "
                  :params="params"
                  :showProductType="false"
                  stackType="all"
              ></event-sales>
              <event-logs
                  :key="ecKey"
                  :eventId="event.id"
                  :global="false"
              ></event-logs>
            </v-tab-item>

            <v-tab-item value="customer">
              <customer-analysis
                  ref="customerChart"
                  :dateToggle="'YEAR'"
                  :displayTopCustomers="true"
                  :event-id="event.id"
                  :exportPermission="
              checkExportPermission($modules.events.management.slug)
            "
                  :params="params"
                  :showChartHeadder="false"
                  :showProductType="false"
                  :showVenueService="false"
                  stackType="all"
              ></customer-analysis>
              <event-customers
                  :key="ecKey"
                  :eventId="event.id"
                  :global="false"
              ></event-customers>
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </div>
    </v-card>
    <event-booking-form
        v-bind="eventForm"
        @booked="openOrderCloseBooking"
        @cancel="getEventDetails"
        @close="eventForm.showeventForm = false"
        @pay="openOrderCloseBooking"
    ></event-booking-form>
    <order-details
        :id="orderId"
        @close="orderId = null"
        @paymentDone="getEventDetails"
    ></order-details>

    <confirm-model
        v-bind="confirmModel"
        @close="confirmModel.id = null"
        @confirm="callCancelEvent"
    >

      <template #content v-if="event.status_id === 1 && confirmModel.data">
        <v-row class="mt-4">
          <v-col cols="6">
            <v-switch
                class="mx-0 my-0"
                v-model="confirmModel.data.notify_customers"
                label="Notify Customers"
                dense
                hide-details="auto"
            ></v-switch>
          </v-col>
          <v-col cols="6" v-if="checkWritePermission($modules.sales.refund.slug) ">
            <v-switch
                class="mx-0 my-0"
                v-model="confirmModel.data.refund_customers"
                label="Refund Customers"
                dense
                hide-details="auto"
            ></v-switch>
          </v-col>
          <v-col cols="12" v-if="checkWritePermission($modules.sales.refund.slug) && confirmModel.data.refund_customers">
            <label for="">Refund To:</label>
            <v-select
                placeholder=""
                v-model="confirmModel.data.refund_type"
                item-value="value"
                item-text="name"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => !!v || 'Select a Refund Type']"
                :items="paymentMethods"
                outlined
                background-color="#fff"
                dense
                class="q-autocomplete shadow-0"
                required
                hide-details="auto"
            ></v-select>
            <span class="text-xs" style="color:red;">Note: All Customers that paid other than Online will be given refund to Wallet</span>
          </v-col>
        </v-row>
      </template>

    </confirm-model>
  </v-container>
</template>

<script>

import SalesIcon from '@/assets/images/events/sales-icon.svg';
import WalletIcon from '@/assets/images/misc/wallet-icon.svg';
import CalendarIcon from '@/assets/images/events/calendar-icon.svg';
import CalendarCleanIcon from '@/assets/images/misc/calendar-icon.svg';
import AttendanceIcon from '@/assets/images/events/attendance-icon.svg';
import CustomerAnalysis from "@/components/Chart/CustomerPieCharts";
import EventSchedules from "./EventDetails/EventSchedules";
import EventDetails from "./EventDetails/EventDetails";
import EventSales from "@/components/Chart/OrderSalesGraph";
import EventLogs from "../Sales/Logs";
import EventCustomers from "../Clients/Customers";
import EventBookingForm from "../Schedule/Event/EventBookingFormNew";
import OrderDetails from "@/components/Order/OrderDetails";
import EventSeatMapDetails from "./EventDetails/EventSeatMapDetails.vue";
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import DetailsIcon from '@/assets/images/events/event-details-icon.svg';
import EditIcon from '@/assets/images/misc/edit-icon.svg';
import AnalyticsIcon from '@/assets/images/events/analytics-icon.svg';
import AddIcon from "@/assets/images/misc/plus-icon.svg";
import DotsIcon from "@/assets/images/events/dots.svg";
import DeleteIcon from "@/assets/images/misc/delete-icon.svg";
import ActivateIcon from "@/assets/images/partners/activate.svg";

export default {
  components: {
    ActivateIcon,
    DeleteIcon,
    DotsIcon,
    AddIcon,
    SvgIcon,
    BackButton,
    CustomerAnalysis,
    EventDetails,
    EventSchedules,
    EventSales,
    EventLogs,
    EventCustomers,
    EventBookingForm,
    OrderDetails,
    EventSeatMapDetails,
    SalesIcon,
    CalendarIcon,
    CalendarCleanIcon,
    AttendanceIcon,
    DetailsIcon,
    EditIcon,
    AnalyticsIcon,
    WalletIcon
  },
  data() {
    return {
      event: {
        documents: [],
        lineups: [],
        tickets: [],
      },
      eventForm: {},
      orderId: null,
      tab: "details",
      params: {venue_service_ids: [], product_ids: [], product_type_ids: [3]},
      ecKey: 0,
      mapReload: false,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      showCancelEvent: false,
    };
  },
  watch: {
    tab(index) {
      if (index && index === "maps") {
        this.mapReload = false;
        this.$nextTick(() => {
          this.mapReload = true;
        });
      }
      if (index && index === "customer") {
        setTimeout(() => {
          if (this.$refs.customerChart) {
            this.$refs.customerChart.getCustomerGraphData();
          }
        }, 100);
      }
      // Call your API or perform other actions
    },
  },
  computed: {
    paymentMethods() {
      let array = ['Wallet','Online'];
      let p_methods = this.$store.getters.getPaymentMethods.data.filter(
          (ele) => {
            return array.includes(ele.name);
          }
      );
      return p_methods;
    },
  },
  mounted() {
    if (typeof this.$route.params.data != "undefined") {
      this.event.id = parseInt(atob(this.$route.params.data));
      this.getEventDetails();
    }
    this.$store.dispatch("loadPaymentMethods", "refund");
    if (this.workshopRefundAmount) {
      this.$forceUpdate();
    }
    if (!this.$store.getters.getFieldConfigurations.status) {
      this.$store.dispatch("loadEventFieldConfigurations", "Event").then(() => {});
    }
  },
  methods: {
    getEventDetails() {
      this.showLoader("Loading");
      this.$http
          .get("venues/events/" + this.event.id)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              let eventData = response.data.data;
              if (eventData.tickets) {
                this.params.product_ids = eventData.tickets.map(
                    (item) => item.product_id
                );
              }
              if (!eventData.event_schedules) {
                eventData.event_schedules = [];
              }
              this.event = eventData;
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    openEventForm() {
      this.eventForm = {
        showeventForm: true,
        start_time: moment(this.event.start_time, "hh:mm a").format("HH:mm:ss"),
        end_time: moment(this.event.end_time, "hh:mm a").format("HH:mm:ss"),
        start_date: this.event.start_date,
        end_date: this.event.end_date,
        event_name: this.event.name,
        facility_id: this.event.facility_id,
        event_id: this.event.id,
        date: moment(this.event.start_date, "YYYY-MM-DD").isAfter()
            ? moment(this.event.start_date, "YYYY-MM-DD").format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
        venue_service_id: this.event.venue_service_id,
        service: this.event.venue_service
            ? this.event.venue_service.service.name
            : "NA",
        id: this.event.id != null ? this.event.id : 0,
        event_type_id: this.event.event_type_id,
        image_path: this.event.image_path,
        location: this.event.location,
        participants: this.event.participants ? this.event.participants : 0,
        sales: this.event.sales ? this.event.sales : 0,
      };
    },
    openOrderCloseBooking(orderId) {
      this.eventForm.showeventForm = false;
      this.orderId = orderId;
      this.getEventDetails();
      /** ecKey just for reloading the custom and log component again */
      if (this.event.seat_map_id) {
        this.mapReload = false;
        this.$nextTick(() => {
          this.mapReload = true;
        });
      }
      this.ecKey++;
    },
    goToEvents() {
      this.$router.push({name: "Events"}, () => {
      });
    },
    editEvent() {
      this.$router.push({
        name: "EventsEdit",
        params: {data: btoa(this.event.id)},
      });
    },

    cancelEvent() {
      this.confirmModel = {
        id: this.event.id,
        title: `Do you want to ${this.event.status_id === 13?'reactivate':'cancel'} this event?`,
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "event",
        data: {
          id: this.event.id,
          year: this.event.year,
          type: this.event.type,
          notify_customers:false,
          refund_customers:false,
          refund_type:{},
        },
      };
    },
    callCancelEvent(data) {
      this.showLoader('Cancelling event, please wait');
      this.$http
          .put(`venues/events/${data.id}/status`,{
            notify_customers: data.data?.notify_customers ?? false,
            refund_customers: data.data?.refund_customers ?? false,
            refund_type: data.data?.refund_type ?? null,
          })
          .then((response) => {
            if (response && response.status == 200 && response.data.status == true) {
              this.confirmModel = {
                id: null,
                title: null,
                description: null,
              }
              this.getEventDetails()
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
            this.hideLoader();
      });
    },
  },
};
</script>

<style>
.eventbox {
  background-color: rgba(8, 8, 8, 0.397);
}

.ticketdetail {
  background-color: rgb(218, 218, 218);
  padding: 5px;
}

.section {
  margin: 20px 0 50px 0;
  padding: 20px 0 20px;
}

.chart_ic {
  background-image: url("../../assets/images/nav_icon/graph.png");
  padding: 10px;
  background-size: contain;
}
</style>
