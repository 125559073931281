<template>
  <v-dialog scrollable :value="showParticipants" max-width="500" @input="close">
    <v-card>
      <v-toolbar color="teal" dark>
        <v-toolbar-title>Attendance</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-list subheader v-if="participants.length > 0">
          <v-list-item
            v-for="participant in participants"
            :key="participant.id"
          >
            <v-list-item-avatar>
              <view-image
                defaultImage="user"
                :image="participant.profile_image"
                :contain="false"
              ></view-image>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ participant.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div
          v-else
          style="height: 200px"
          class="d-flex justify-center align-center"
        >
          No Participants
        </div>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="participants.length > 0"
          @click="downloadPdf()"
          class="ma-2 black--text yellow-color"
          text
          >Print
        </v-btn>

        <v-btn @click="close()" class="ma-2 white--text blue-color" text
          >Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    "show-participants": { type: Boolean, default: false },
    order_id: { type: Number },
    refresh: { type: Boolean },
  },
  watch: {
    showParticipants(val) {
      if (val == true) {
        this.openParticipants();
      }
    },
    refresh() {
      this.openParticipants();
    },
  },
  data() {
    return {
      participants: [],
    };
  },
  methods: {
    openParticipants() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/facilities/bookings/attendance?order_id=${this.order_id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.participants = data;
            this.hideLoader();
          }
        });
    },
    close() {
      this.$emit("close");
    },
    openBooking(data) {
      data.status = data.status_id == 5 ? "unpaid" : "paid";
      this.$emit("open-booking", data);
    },
    downloadPdf() {
      this.showLoader("Generating..");
      this.$http
        .get(`venues/orders/attendance-pdf/${this.participants[0].order_id}`, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == 200) {
            this.downloadFile(response, `OrderCustomers`, "pdf");
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style></style>
