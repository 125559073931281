<template>
<v-container fluid>
    <v-row v-if="mapData">
        <v-col lg="8" md="8" sm="12">
            <v-card class="seat-map-editor">
                <v-card-actions>
                    <div class="heading"><h3>Map Name: {{ mapData.map_name }}</h3></div>
                </v-card-actions>
                <v-divider></v-divider>
                <div class="parent-map-canvas">
                    <SeatMapBookingCanvas 
                        :renderCanvas="renderCanvas" 
                        :renderSection="renderSection" 
                        :mapData="mapData" 
                        :mapSections="mapData.sections" 
                        :seatMapStyles="seatMapStyles"
                    />
                </div>
            </v-card>
        </v-col>
        <v-col lg="4" md="4" sm="12" >
           <EventSeatTicketAssignCard v-if="mapData" :mapData="mapData" :tickets="event.tickets" @assignSeatModel="assignSeatModel"/>
        </v-col>
    </v-row>
     <v-dialog v-model="sectionDialog" persistent max-width="800" v-if="section">
        <EventSeatTicketAssignForm 
            :section="section" 
            :seatAD="seatAD"
            :eventId="event.id"  
            :tickets="event.tickets" 
            @close="closeEdit" 
            @save="save" 
        />
    </v-dialog>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
 </v-container>

</template>
<script>
import SeatMapBookingCanvas from '@/components/SeatMap/SeatMapBookingCanvas.vue';
import EventSeatTicketAssignCard from '@/components/Event/EventSeatTicketAssignCard.vue';
import EventSeatTicketAssignForm from '@/components/Event/EventSeatTicketAssignForm.vue';

import ConfirmModel from "@/components/Confirmation/ConfirmModel";

export default {
    components: {
        SeatMapBookingCanvas,
        EventSeatTicketAssignCard,
        EventSeatTicketAssignForm,
        ConfirmModel
    },
    props: {
        event: { type: Object, default: null},
    },
    data() {
        return {
            valid: true,
            sectionDialog: false,
            confirmModel: {
                id: null,
                title: null,
                description: null,
            },
            mapData: {},
            section: null,
            mapSections: [],
            renderCanvas: false,
            renderSection: false,
            seatAD: false,
        };
    },
    computed: {
        seatMapStyles() {
            return {
                backgroundColor: this.mapData.map_bg_color,
                width: this.mapData.map_width+"px",
                height: this.mapData.map_height+"px",
                margin: "0 auto",
                border: "1px solid "+this.mapData.map_bg_color,
                borderRadius:"1px",
            };
        },
    },
    mounted() {
        console.log("event ticket seat map");
        if (this.event && this.event.seat_map_id) {
            this.setMapData();
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        confirmActions(data) {
            if (data.type == "delete_section") {
                this.deleteSection(data.id);
            }
            this.confirmModel.id = null;
        },
        setMapData() {
            if (this.event && this.event.seat_map_id) {
                this.showLoader("Loading");
                this.$http.get(`venues/events/event-map?event_id=${this.event.id}`)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data && response.data.data) {
                            this.mapData.isPlanSet = true;
                            this.mapData = response.data.data.seat_map;
                             this.renderCanvas = false;
                            this.$nextTick(() => {
                                this.renderCanvas = true;
                            })
                        }else{
                            this.showError('Data not found');
                        }
                        this.hideLoader();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.hideLoader();
                });  
            }
        },
        closeEdit() {
            this.seatAD = false;
            this.sectionDialog = false;
            this.section = null; 
        },
        assignSeatModel(s, action = null) {
            this.seatAD = false;
            this.section = s;
            if (!s.is_specific && !action) {
                const seatWithEventTicketId = s.seats.find(seat => seat.event_ticket_id !== undefined);
                if (seatWithEventTicketId) {
                    this.section.event_ticket_id = seatWithEventTicketId.event_ticket_id;
                }
            } else {
                if (action) {
                     this.seatAD = true;
                }
            }
            this.filterBookedSeats(s);
            this.sectionDialog = true;
        },
        filterBookedSeats(mapSection) {
            if (mapSection.booked_seats && mapSection.booked_seats.length) {
                const bookedSeats = mapSection.booked_seats;
                mapSection.seats.forEach((seat) => {
                    let seatFind = bookedSeats.find((bs) => bs.seat_name === seat.seat_name && bs.seat_map_section_id === seat.seat_map_section_id);
                    if (seatFind !== undefined) {
                        seat.is_booked = true;
                        seat.status = seatFind.order_status_id == 4?'paid':'unpaid';
                    }
                })
            }
            this.section = Object.assign({}, mapSection);
        },
        save() {
            this.seatAD = false;
            this.sectionDialog = false;
            this.section = null;
            this.setMapData();
        }
        


    }
};
</script>
<style>
.seat-map-editor {
  position: relative;
}
.parent-map-canvas{
    width: 100%;
    overflow: auto;
    margin-top:5px;
    min-height: 480px;
}
.seating-card{
    overflow-x: auto;
}


.plan-cf-action-heading {
    color: var(--dark-blue, #112A46);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 18px;
}
</style>