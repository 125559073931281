<template>
    <v-form ref="form" v-model="valid" class="ticket-setting-form">
    <v-card>
        <v-card-actions>
            <v-row>
                <v-col md="9">
                    <div class="plan-cf-action-heading" v-if="!seatAD">Seat Map Ticket Assign</div>
                    <div class="plan-cf-action-heading" v-else>Activate / Deactivate Seats</div>
                </v-col>
                    <v-col md="3" style="text-align:right;">
                        <v-btn
                        color="darken-1"
                        class="ma-2 text"
                        text
                        @click="closeEdit"
                        >X</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>

        <v-card-text>
            <v-container>
                <v-row v-if="!seatAD">
                    <v-col cols="6" sm="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                            hide-details="auto"
                            label="Section Name"
                            outlined
                            background-color="#rgb(222 221 222)"
                            placeholder="section name"
                            v-model="section.section_name"
                            :rules="[(v) => !!v || 'Section name is required']"
                            required
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="12" md="6" class="pt-0 pb-0">
                         <v-select
                            v-model="section.event_ticket_id"
                            :items="tickets"
                            item-value="event_ticket_id"
                            item-text="name"
                            outlined
                            :menu-props="{ bottom: true, offsetY: true }"
                            background-color="#fff"
                            :rules="[(v) => !!v || 'Ticket is required']"
                            :label="`Select Ticket*`"
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                        <v-radio-group
                          v-model="section.is_specific"
                          row
                          class="pl-2"
                          label="Ticket Type: "
                          @change="changeSectionType"
                          >
                            <v-radio label="General" :value="0"></v-radio>
                            <v-radio label="Specific" :value="1"></v-radio> 
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row v-if="(section.is_specific && tickets && tickets.length) || seatAD" >
                    <MapSectionSeats 
                        :mapSection="section" 
                        :seatMapId="section.seat_map_id"
                        :tickets="tickets"  
                        :eventTicketAssignForm="true"
                        :isShowToolTip="true" 
                        :seatAD="seatAD"
                        @close="closeEdit" 
                        @save="saveSection" 
                        :key="renderSeatSection" 
                        @closeUpdate="closeUpdateMapSectionSeat" />
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions v-if="!seatAD">
            <v-btn
                text
                class="ma-2"
                @click="closeEdit"
                >Close</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
                color=" darken-1"
                class="ma-2 white--text blue-color"
                @click="save"
                text
            >Save Changes</v-btn>
        </v-card-actions>
    </v-card>
    </v-form>
</template>
<script>
import MapSectionSeats from '@/components/SeatMap/MapSectionSeats.vue';
export default {
    components: {
        MapSectionSeats,
    },
    props: {
        tickets: { type: Array, default: () => [], },
        section: { type: Object, default: null },
        eventId: { type: Number, default: null },
        seatAD: { type: Boolean, default: false},
    },

    data() {
        return {
            valid: true,
            renderSeatSection: 0,
        };
    },
    mounted() {
        // if (this.section && this.section.section_tickets) {
        //     this.event_ticket_id = this.section.section_tickets.event_ticket_id;
        // }
    },
    methods: {
        closeEdit() {
            this.section.seats.forEach((seat) => {
                seat.is_selected = false;
            })
            this.$emit('close');
        },
        save() {
            if (!this.$refs.form.validate()) {
                this.showError("Fill all required fields");
                return;
            }
            this.showLoader('wait');
            let jsonData = {
                event_id: this.eventId,
                seat_map_id: this.section.seat_map_id,
                seat_map_section_id: this.section.seat_map_section_id,
                seats: []
            };
            if (this.section.is_specific) {
                jsonData.is_specific = 1;
            } else {
                jsonData.is_specific = 0;
            }
            /** prepare object for saving info */
            let seats = [];
            if (this.section.is_specific) {
                this.section.seats.forEach((seat) => {
                    let sob = null;
                    if (seat.status_id == 1 && seat.is_selected) {
                        sob = {
                            seat_map_section_id: seat.seat_map_section_id,
                            seat_name: seat.seat_name,
                            status_id: 1,
                            event_ticket_id: this.section.event_ticket_id,
                        };
                    } else {
                        sob = {
                            seat_map_section_id: seat.seat_map_section_id,
                            seat_name: seat.seat_name,
                            status_id: seat.status_id,
                        };
                        if (seat.event_ticket_id && seat.status_id == 1) {
                            sob.event_ticket_id = seat.event_ticket_id;
                        }
                    }
                    seats.push(sob);
                });
            } else if (!this.section.is_specific) {
                this.section.seats.forEach((seat) => {
                    let sob = null;
                    sob = {
                        seat_map_section_id: seat.seat_map_section_id,
                        seat_name: seat.seat_name,
                        status_id: seat.status_id,
                    };
                    if (seat.status_id == 1) {
                        sob.event_ticket_id = this.section.event_ticket_id;
                    }
                    seats.push(sob);
                });
            }
            jsonData.seats = seats;
            this.$http.post("venues/events/event-map/update", jsonData).then((response) => {
                if (response.status == 200 && response.data.status == true) {
                    this.showSuccess("Ticket assign successfully.");
                    this.hideLoader();
                    this.$emit('save', response.data)
                }
            }).catch((error) => {
                this.errorChecker(error);
            });
        },
        closeUpdate() {
            
        },
        saveSection() {
            
        },
        closeUpdateMapSectionSeat() {
             this.$emit('save')
        },
        changeSectionType() {
            if (!this.is_specific) {
                this.section.seats.forEach((seat) => {
                    seat.is_selected = false;
                })
            }
        }
        


    }
};
</script>